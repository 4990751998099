import _ from 'lodash';
import { createContext, forwardRef, useContext, useMemo, useRef } from 'react';
import { LayoutGridType } from 'store/feature/layoutsSlice/models';
import { GridComponent, GridComponentRef } from './models';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import { GridControlContext, useGridControl } from './useGridControl';

export interface GridContext {
    gridId: string;
    gridRef: GridComponentRef;
    gridType: LayoutGridType;
    gridControl: GridControlContext;
}

const GridContextInternal = createContext<GridContext>({} as GridContext);

export interface GridContextProviderProps {
    gridType: LayoutGridType;
}

export const GridContextProvider: React.FC<
    React.PropsWithChildren<GridContextProviderProps>
> = ({ gridType, children }) => {
    const gridId = useMemo(() => _.uniqueId('grid-'), []);
    const gridRef = useRef<GridComponent>(null);
    const gridControl = useGridControl(gridType, gridRef);

    const value: GridContext = { gridId, gridRef, gridType, gridControl };

    return (
        <GridContextInternal.Provider value={value}>
            {children}
        </GridContextInternal.Provider>
    );
};

function useGridContext(): GridContext {
    return useContext(GridContextInternal);
}

export function bindToGrid({
    gridRef,
    gridControl,
}: GridContext): Partial<AgGridReactProps & React.RefAttributes<AgGridReact>> {
    return {
        ref: gridRef,
        gridOptions: { ...gridControl.gridOptions },
    };
}

// for use in legacy components only
export function withGridContext<P>(
    Component: React.ComponentType<P>
): React.ComponentType<any> {
    return forwardRef<GridComponent, P & { gridContext: GridContext }>(
        function WithGridContext(props, ref) {
            const gridContext = useGridContext();
            return <Component ref={ref} {...props} gridContext={gridContext} />;
        }
    );
}

export default useGridContext;
