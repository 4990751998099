import SavedFiltersForm from './SavedFiltersForm';
import UnsavedFiltersForm from './UnsavedFiltersForm';
import { Box, Divider, FormControl, styled } from '@mui/material';

const StyledBox = styled(Box)({
    display: 'flex',
    '& .MuiButton-root': {
        backgroundColor: '#3f51b5 !important',
    },
    '& .MuiCheckbox-colorPrimary': {
        color: '#3f51b5 !important',
    },
});

const FiltersMenu = (props) => {
    return (
        <StyledBox>
            <FormControl component="fieldset" sx={{ margin: '20px' }}>
                <SavedFiltersForm {...props} />
                {props.savedFilters.length > 0 &&
                props.unsavedFilters.length > 0 ? (
                    <Divider />
                ) : null}
                <UnsavedFiltersForm {...props} />
            </FormControl>
        </StyledBox>
    );
};

export default FiltersMenu;
