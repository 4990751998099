import { Chip, styled } from '@mui/material';

const StyledChip = styled(Chip)(({ theme }) => ({
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.common.white,
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#757575',
    textAlign: 'right',
    alignItems: 'center',
    border: '1px solid #757575',
}));

export const ChipOutlined = ({ label, onDelete }) => {
    return (
        <StyledChip
            variant="outlined"
            size="small"
            label={label}
            onDelete={onDelete}
        />
    );
};
