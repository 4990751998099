import React, { Component } from 'react';
import DateNodeTree from '../../../tools/DateNodeTree';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import PlusIcon from '../../icons/PlusIcon';
import MinusIcon from '../../icons/MinusIcon';
import PropTypes from 'prop-types';
import CountryImage from '../../common/CountryImage';

class FilterTreeView extends Component {
    constructor(props) {
        super(props);
        this.handleNodeToggled = this.handleNodeToggled.bind(this);
    }

    getExpandedNodes(visibleNodes, expanded) {
        const result = [];
        const addWithParents = (n, res) => {
            if (!res.includes(n.key)) {
                res.push(n.key);
                if (n.parent) {
                    addWithParents(n.parent, res);
                }
            }
        };
        const forNodeLeafsAddWithParents = (nodes, res) => {
            nodes.forEach((n) => {
                if (expanded.includes(n.key)) {
                    addWithParents(n, result);
                }
                if (n.children && n.children.length > 0) {
                    forNodeLeafsAddWithParents(n.children, res);
                }
            });
        };
        forNodeLeafsAddWithParents(visibleNodes, result);
        return result;
    }

    renderNode(treeNode, visibleNodes) {
        const checked = treeNode.state === DateNodeTree.States.CHECKED;
        const indeterminate =
            treeNode.state === DateNodeTree.States.INDETERMINATE;

        const checkBox = (
            <div>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={(event) => {
                        this.props.onNodeClicked(treeNode, !checked);
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{
                        top: '1px',
                        position: 'relative',
                        marginRight: '5px',
                    }}
                    ref={(el) => el && (el.indeterminate = indeterminate)}
                />
                <span>
                    <CountryImage countryCode={treeNode.countryCode} />
                    {treeNode.value.toUpperCase()}
                </span>
            </div>
        );

        return (
            <TreeItem
                itemId={treeNode.key.toString()}
                key={treeNode.key}
                label={checkBox}
                sx={{ '& .MuiTreeItem-content': { padding: '0 4px' } }}
            >
                {treeNode.children
                    .filter((n) => visibleNodes[n.key])
                    .map((n) => this.renderNode(n, visibleNodes))}
            </TreeItem>
        );
    }

    handleNodeToggled(event, nodes) {
        if (this.props.onNodeToggle) {
            this.props.onNodeToggle(nodes);
        }
    }

    render() {
        const years = this.props.tree.getYears();
        const visibleNodes = years.filter(
            (n) => this.props.visibleNodes[n.key]
        );
        const expandedNodes = this.getExpandedNodes(
            visibleNodes,
            this.props.expanded ?? []
        );

        return (
            <SimpleTreeView
                slots={{
                    collapseIcon: MinusIcon,
                    expandIcon: PlusIcon,
                }}
                slotProps={{
                    collapseIcon: { style: { width: '12px' } },
                    expandIcon: { style: { width: '12px' } },
                }}
                expandedItems={expandedNodes}
                onExpandedItemsChange={this.handleNodeToggled}
                sx={{
                    transitionDuration: 0,
                    '&, & *': {
                        fontFamily: 'Roboto',
                    },
                }}
            >
                {visibleNodes.map((n) =>
                    this.renderNode(n, this.props.visibleNodes)
                )}
            </SimpleTreeView>
        );
    }
}

FilterTreeView.defaultProps = {
    tree: [],
};

FilterTreeView.propTypes = {
    onNodeClicked: PropTypes.func.isRequired,
};

export default FilterTreeView;
