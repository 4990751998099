import { ListenerMiddlewareInstance } from '@reduxjs/toolkit';
import MixpanelLogger from '_legacy/diagnostics/logging/mixpanelLogger';
import { RootState } from 'store';
import {
    createTemplateLayout,
    createUserLayout,
    deleteTemplateLayout,
    deleteUserLayout,
    layoutsSelectors,
    revertLayout,
    selectLayout,
    updateTemplateLayout,
    updateUserLayout,
} from 'store/feature/layoutsSlice';
import * as LayoutTools from '_legacy/tools/LayoutTools';
import { LayoutGridType } from 'store/feature/layoutsSlice/models';
import { SavedLayoutFragment } from 'api/fragments';

function generateCreateLayoutProperties(
    gridType: LayoutGridType,
    model: SavedLayoutFragment,
    datasetId: number,
    isTemplate: boolean,
    isFromTemplate: boolean
): any {
    const settings = gridType === 'orders' ? model.orders : model.fixtures;
    const layoutType = gridType === 'orders' ? 'Order' : 'Fixture';
    return {
        ...LayoutTools.getCreatedLayoutPropertiesForMixpanel(
            settings,
            ['privacyWarning', 'isSelected', 'privacy'],
            layoutType,
            model.commonSettings.directionLogic,
            model.commonSettings.quantityFormat,
            datasetId,
            {}
        ),
        isTemplate,
        isFromTemplate,
    };
}

function generateSaveLayoutProperties(
    gridType: LayoutGridType,
    model: SavedLayoutFragment,
    datasetId: number,
    isTemplate: boolean
): any {
    const settings = JSON.parse(
        (gridType === 'orders' ? model.orders : model.fixtures) ?? '{}'
    );
    const layoutType = gridType === 'orders' ? 'Order' : 'Fixture';
    return {
        ...LayoutTools.getSavedLayoutPropertiesForMixpanel(
            {
                currentOptions: {
                    ...settings,
                    columnOptions: settings.columnState ?? {},
                    filterOptions: settings.filters ?? {},
                },
            },
            ['privacyWarning', 'isSelected', 'privacy'],
            layoutType,
            model.commonSettings.directionLogic,
            model.commonSettings.quantityFormat,
            datasetId,
            {}
        ),
        isTemplate,
    };
}

function configureMixpanelEvents(
    listener: ListenerMiddlewareInstance<RootState>
): void {
    listener.startListening({
        actionCreator: selectLayout,
        effect: (_, { getState }) => {
            const selectedLayout = layoutsSelectors.selectedLayout(getState());
            MixpanelLogger.trackSwitchLayoutEvent(
                selectedLayout.name,
                selectedLayout.isTemplate
            );
        },
    });
    listener.startListening({
        actionCreator: revertLayout,
        effect: (_, { getState }) => {
            const selectedLayout = layoutsSelectors.selectedLayout(getState());
            MixpanelLogger.trackRevertLayoutEvent(
                selectedLayout.name,
                selectedLayout.isTemplate
            );
        },
    });

    listener.startListening({
        actionCreator: deleteUserLayout.fulfilled,
        effect: ({ payload: result }) => {
            MixpanelLogger.trackDeleteLayoutEvent(result.name, false);
        },
    });
    listener.startListening({
        actionCreator: deleteTemplateLayout.fulfilled,
        effect: ({ payload: result }) => {
            MixpanelLogger.trackDeleteLayoutEvent(result.name, true);
        },
    });

    listener.startListening({
        actionCreator: createUserLayout.fulfilled,
        effect: (
            {
                payload: { result, isFromTemplate },
                meta: {
                    arg: { gridType },
                },
            },
            { getState }
        ) => {
            const datasetId = layoutsSelectors.selectedDatasetId(getState());
            MixpanelLogger.trackCreateLayoutEvent(
                result.name,
                generateCreateLayoutProperties(
                    gridType,
                    result,
                    datasetId ?? 0,
                    false,
                    isFromTemplate
                )
            );
        },
    });
    listener.startListening({
        actionCreator: createTemplateLayout.fulfilled,
        effect: (
            {
                payload: { result, isFromTemplate },
                meta: {
                    arg: { gridType },
                },
            },
            { getState }
        ) => {
            const datasetId = layoutsSelectors.selectedDatasetId(getState());
            MixpanelLogger.trackCreateLayoutEvent(
                result.name,
                generateCreateLayoutProperties(
                    gridType,
                    result,
                    datasetId ?? 0,
                    true,
                    isFromTemplate
                )
            );
        },
    });

    listener.startListening({
        actionCreator: updateUserLayout.fulfilled,
        effect: (
            {
                payload: result,
                meta: {
                    arg: { gridType },
                },
            },
            { getState }
        ) => {
            const datasetId = layoutsSelectors.selectedDatasetId(getState());
            MixpanelLogger.trackSaveLayoutEvent(
                result.name,
                generateSaveLayoutProperties(
                    gridType,
                    result,
                    datasetId ?? 0,
                    false
                )
            );
        },
    });
    listener.startListening({
        actionCreator: updateTemplateLayout.fulfilled,
        effect: (
            {
                payload: result,
                meta: {
                    arg: { gridType },
                },
            },
            { getState }
        ) => {
            const datasetId = layoutsSelectors.selectedDatasetId(getState());
            MixpanelLogger.trackSaveLayoutEvent(
                result.name,
                generateSaveLayoutProperties(
                    gridType,
                    result,
                    datasetId ?? 0,
                    true
                )
            );
        },
    });
}

export default configureMixpanelEvents;
