import { isTestMode } from '../../config';
import {
    createAddNewOrderEvent,
    createAddNewFixtureEvent,
    createOpenAuditHistoryEvent,
    createReInstateDeletedItemEvent,
    createDeleteItemEvent,
    createOpenAnalyticsEvent,
    createOpenAdvancedAnalyticsEvent,
    createCreateLayoutEvent,
    createEditLayoutNameEvent,
    createDeleteLayoutEvent,
    createSwitchLayoutEvent,
    createSaveLayoutEvent,
    createRevertLayoutEvent,
    createExportToEmailEvent,
    createExportToExcelEvent,
    createConvertOrderToFixtureEvent,
    createChangeVisibilityItemEvent,
    createChangeConfidentialityItemEvent,
    createDataFilterAppliedEvent,
    createDeliveryTermVisibilityChangeEvent,
    createDeliveryTermValueChangedEvent,
    createNewUIToggleEvent,
    createNewUIDensityChangeEvent,
    createCompanyDropdownButtonClickEvent,
    createCompanyFormCancelClickEvent,
    createCompanyFormCreateClickEvent,
} from './mixpanelEventCreator';
class MixpanelLogger {
    //setting Mock just for test purposes
    static seaHeader = MixpanelLogger.createSeaHeaderMock();

    static initialize(seaHeader) {
        MixpanelLogger.seaHeader = seaHeader;
    }

    static createSeaHeaderMock() {
        return {
            user: {
                name: 'test',
            },
        };
    }

    static trackNewOrderEvent(
        orderId,
        groupOnly,
        privacy,
        isIndividual,
        fortGroup,
        methodOfAddition,
        datasetId
    ) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createAddNewOrderEvent(
            orderId,
            groupOnly,
            privacy,
            isIndividual,
            fortGroup,
            userName,
            methodOfAddition,
            datasetId
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackNewFixtureEvent(
        fixtureId,
        groupOnly,
        privacy,
        isIndividual,
        fortGroup,
        methodOfAddition,
        datasetId
    ) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createAddNewFixtureEvent(
            fixtureId,
            groupOnly,
            privacy,
            isIndividual,
            fortGroup,
            userName,
            methodOfAddition,
            datasetId
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackDeleteItemEvent(
        entityId,
        entityName,
        groupOnly,
        privacy,
        fortGroup
    ) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createDeleteItemEvent(
            entityId,
            entityName,
            groupOnly,
            privacy,
            fortGroup,
            userName
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackReInstateDeletedItemEvent(
        entityId,
        entityName,
        groupOnly,
        privacy,
        fortGroup
    ) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createReInstateDeletedItemEvent(
            entityId,
            entityName,
            groupOnly,
            privacy,
            fortGroup,
            userName
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackOpenAuditHistoryEvent(
        entityId,
        entityName,
        groupOnly,
        privacy,
        fortGroup
    ) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createOpenAuditHistoryEvent(
            entityId,
            entityName,
            groupOnly,
            privacy,
            fortGroup,
            userName
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackOpenAnalyticsEvent(fortGroup) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createOpenAnalyticsEvent(fortGroup, userName);

        MixpanelLogger.trackEvent(event);
    }

    static trackOpenAdvancedAnalyticsEvent(fortGroup) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createOpenAdvancedAnalyticsEvent(fortGroup, userName);

        MixpanelLogger.trackEvent(event);
    }

    static trackCreateLayoutEvent(layoutName, layoutProperties) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createCreateLayoutEvent(
            userName,
            layoutName,
            layoutProperties
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackEditLayoutNameEvent(layoutName) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createEditLayoutNameEvent(userName, layoutName);

        MixpanelLogger.trackEvent(event);
    }

    static trackDeleteLayoutEvent(layoutName, isTemplate) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createDeleteLayoutEvent(userName, layoutName, isTemplate);

        MixpanelLogger.trackEvent(event);
    }

    static trackSwitchLayoutEvent(layoutName, isTemplate) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createSwitchLayoutEvent(userName, layoutName, isTemplate);

        MixpanelLogger.trackEvent(event);
    }

    static trackSaveLayoutEvent(layoutName, layoutProperties) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createSaveLayoutEvent(
            userName,
            layoutName,
            layoutProperties
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackRevertLayoutEvent(layoutName, isTemplate) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createRevertLayoutEvent(userName, layoutName, isTemplate);

        MixpanelLogger.trackEvent(event);
    }

    static trackExportToEmailEvent(
        type,
        noOfRowsExported,
        includesConfidentialData
    ) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createExportToEmailEvent(
            userName,
            type,
            noOfRowsExported,
            includesConfidentialData
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackExportToExcelEvent(
        type,
        noOfRowsExported,
        includesConfidentialData,
        includesGroupHeadings
    ) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createExportToExcelEvent(
            userName,
            type,
            noOfRowsExported,
            includesConfidentialData,
            includesGroupHeadings
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackConvertOrderToFixtureEvent(
        groupOnly,
        privacy,
        fixtureId,
        orderId,
        fortGroup
    ) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createConvertOrderToFixtureEvent(
            groupOnly,
            privacy,
            fixtureId,
            orderId,
            fortGroup,
            userName
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackChangeVisibilityEvent(props) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createChangeVisibilityItemEvent({ ...props, userName });

        MixpanelLogger.trackEvent(event);
    }

    static trackChangeConfidentialityEvent(props) {
        const userName = MixpanelLogger.seaHeader.user.name;
        const event = createChangeConfidentialityItemEvent({
            ...props,
            userName,
        });

        MixpanelLogger.trackEvent(event);
    }

    static trackDataFilterToggle(
        tabName,
        datasetId,
        fortGroupName,
        filterName,
        filterValue
    ) {
        const userName = MixpanelLogger.seaHeader.user.name;

        const event = createDataFilterAppliedEvent(
            userName,
            tabName,
            datasetId,
            fortGroupName,
            filterName,
            filterValue
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackNewUIToggle(toggleValue) {
        const userName = MixpanelLogger.seaHeader.user.name;

        const event = createNewUIToggleEvent(userName, '1.0', toggleValue);

        MixpanelLogger.trackEvent(event);
    }

    static trackNewUIDensityChange(compactDensityValue) {
        const userName = MixpanelLogger.seaHeader.user.name;

        const event = createNewUIDensityChangeEvent(
            userName,
            compactDensityValue
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackDeliveryTermValueChanged = (value) => {
        const userName = MixpanelLogger.seaHeader.user.name;

        const event = createDeliveryTermValueChangedEvent(userName, value);

        MixpanelLogger.trackEvent(event);
    };

    static trackDeliveryTermVisibilityChange(isVisible) {
        const userName = MixpanelLogger.seaHeader.user.name;

        const event = createDeliveryTermVisibilityChangeEvent(
            userName,
            isVisible
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackCreateCompanyDropdownButtonClick(columnName, isNonClient) {
        const userName = MixpanelLogger.seaHeader.user.name;

        const event = createCompanyDropdownButtonClickEvent(
            userName,
            columnName,
            isNonClient
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackCompanyFormCreateClick(companyName) {
        const userName = MixpanelLogger.seaHeader.user.name;

        const event = createCompanyFormCreateClickEvent(userName, companyName);

        MixpanelLogger.trackEvent(event);
    }

    static trackCompanyFormCancelClick(companyName, cancelSource) {
        const userName = MixpanelLogger.seaHeader.user.name;

        const event = createCompanyFormCancelClickEvent(
            userName,
            companyName,
            cancelSource
        );

        MixpanelLogger.trackEvent(event);
    }

    static trackEvent(event) {
        if (MixpanelLogger.seaHeader && !isTestMode) {
            MixpanelLogger.seaHeader.trackEvent(event.name, event.payload);
        }
    }
}

export default MixpanelLogger;
