import * as types from './actionTypes';
import { fetchSelectedLayoutId, saveSelectedLayoutId } from '../localStorage';
import store from 'store';
import isEqual from 'lodash/isEqual';
import { getDataset } from '../models/Datasets';
import { getSelectedLayoutOptions } from './utils';

export const onLoadLayout = (layouts, datasetId) => {
    const sortedLayouts = sortLayouts(layouts);

    // If user refreshes the screen or there has been a recent release then select the last layout that user was working on
    // If user opens the appliction from a new tab then select the preferred layout
    let selectedLayoutId = fetchSelectedLayoutId();

    // Handle the case where Selected Layout may have been deleted from database
    let selectedLayout = sortedLayouts.find((l) => l.id === selectedLayoutId);
    if (!selectedLayout) selectedLayoutId = null;

    if (selectedLayoutId == null) {
        const isPreferredLayout = sortedLayouts.find((l) => l.isPreferred);
        selectedLayoutId = isPreferredLayout ? isPreferredLayout.id : null;
    }

    selectedLayout = sortedLayouts.find((l) => l.id === selectedLayoutId);

    return {
        type: types.LAYOUT_ON_LOAD,
        allLayouts: sortedLayouts,
        selectedLayout: getSelectedLayoutOptions(selectedLayout, datasetId),
    };
};

export const onUpdateLayouts = (layouts) => {
    const userLayouts = sortLayouts(layouts);

    return {
        type: types.LAYOUT_ON_UPDATE,
        allLayouts: userLayouts,
    };
};

export const onSelectLayout = (layouts, selectedLayoutId, datasetId) => {
    const sortedLayouts = sortLayouts(layouts);
    const selectedLayout = sortedLayouts.find((l) => l.id === selectedLayoutId);

    saveSelectedLayoutId(selectedLayoutId);

    return {
        type: types.LAYOUT_ON_SELECT,
        allLayouts: sortedLayouts,
        selectedLayout: getSelectedLayoutOptions(selectedLayout, datasetId),
    };
};

export const onDirectionLogicChanged = (currentDirectionLogic) => {
    const state = store.getState();
    const datasetId = state.user.group.datasetId;
    const selectedLayout = state.layouts.selectedLayout;

    const directionLogic = {
        initialState: selectedLayout.commonSettings.directionLogic.initialState,
        currentState: currentDirectionLogic,
    };

    const {
        wereFixturesOptionsChanged,
        wereOrdersOptionsChanged,
        wereDirectionLogicChanged,
        wereQuantityFormatChanged,
        wereDefaultTypeChanged,
    } = compareCurrentLayoutWithInitialOne(
        datasetId,
        selectedLayout.orders,
        selectedLayout.fixtures,
        directionLogic,
        selectedLayout.commonSettings.quantityFormat,
        selectedLayout.commonSettings.defaultType
    );

    const areThereAnyUnsavedLayoutChanges =
        wereFixturesOptionsChanged ||
        wereOrdersOptionsChanged ||
        wereDirectionLogicChanged ||
        wereQuantityFormatChanged ||
        wereDefaultTypeChanged;

    return {
        type: types.LAYOUT_ON_DIRECTION_LOGIC_CHANGED,
        areThereAnyUnsavedLayoutChanges: areThereAnyUnsavedLayoutChanges,
        currentDirectionLogic: currentDirectionLogic,
    };
};

export const onQuantityFormatChanged = (currentQuantityFormat) => {
    const state = store.getState();
    const selectedLayout = state.layouts.selectedLayout;
    const datasetId = state.user.group.datasetId;

    const quantityFormat = {
        initialState: selectedLayout.commonSettings.quantityFormat.initialState,
        currentState: currentQuantityFormat,
    };

    const {
        wereFixturesOptionsChanged,
        wereOrdersOptionsChanged,
        wereDirectionLogicChanged,
        wereQuantityFormatChanged,
        wereDefaultTypeChanged,
    } = compareCurrentLayoutWithInitialOne(
        datasetId,
        selectedLayout.orders,
        selectedLayout.fixtures,
        selectedLayout.commonSettings.directionLogic,
        quantityFormat,
        selectedLayout.commonSettings.defaultType
    );

    const areThereAnyUnsavedLayoutChanges =
        wereFixturesOptionsChanged ||
        wereOrdersOptionsChanged ||
        wereDirectionLogicChanged ||
        wereQuantityFormatChanged ||
        wereDefaultTypeChanged;

    return {
        type: types.LAYOUT_ON_QUANTITY_FORMAT_CHANGED,
        areThereAnyUnsavedLayoutChanges: areThereAnyUnsavedLayoutChanges,
        currentQuantityFormat: currentQuantityFormat,
    };
};

export const onDefaultTypeChanged = (currentDefaultType) => {
    const state = store.getState();
    const selectedLayout = state.layouts.selectedLayout;
    const datasetId = state.user.group.datasetId;

    const defaultType = {
        initialState: selectedLayout.commonSettings.defaultType.initialState,
        currentState: currentDefaultType,
    };

    const {
        wereFixturesOptionsChanged,
        wereOrdersOptionsChanged,
        wereDirectionLogicChanged,
        wereQuantityFormatChanged,
        wereDefaultTypeChanged,
    } = compareCurrentLayoutWithInitialOne(
        datasetId,
        selectedLayout.orders,
        selectedLayout.fixtures,
        selectedLayout.commonSettings.directionLogic,
        selectedLayout.commonSettings.quantityFormat,
        defaultType
    );

    const areThereAnyUnsavedLayoutChanges =
        wereFixturesOptionsChanged ||
        wereOrdersOptionsChanged ||
        wereDirectionLogicChanged ||
        wereQuantityFormatChanged ||
        wereDefaultTypeChanged;

    return {
        type: types.LAYOUT_ON_DEFAULT_TYPE_CHANGED,
        areThereAnyUnsavedLayoutChanges: areThereAnyUnsavedLayoutChanges,
        currentDefaultType: currentDefaultType,
    };
};

export const onSaveLayout = () => {
    return {
        type: types.LAYOUT_ON_SAVE,
    };
};

export const isCurrentDirectionLogicEqualToInitialOne = (directionLogic) => {
    return directionLogic.initialState === directionLogic.currentState;
};

export const isCurrentQuantityFormatEqualToInitialOne = (quantityFormat) => {
    return quantityFormat.initialState === quantityFormat.currentState;
};

export const isCurrentDefaultTypeEqualToInitialOne = (defaultType) => {
    return defaultType.initialState === defaultType.currentState;
};

const compareCurrentLayoutWithInitialOne = (
    datasetId,
    ordersOptions,
    fixturesOptions,
    directionLogic,
    quantityFormat,
    defaultType
) => {
    return {
        wereOrdersOptionsChanged: !isTheCurrentOrdersLayoutEqualToTheInitialOne(
            datasetId,
            ordersOptions
        ),
        wereFixturesOptionsChanged:
            !isTheCurrentFixturesLayoutEqualToTheInitialOne(
                datasetId,
                fixturesOptions
            ),
        wereDirectionLogicChanged:
            !isCurrentDirectionLogicEqualToInitialOne(directionLogic),
        wereQuantityFormatChanged:
            !isCurrentQuantityFormatEqualToInitialOne(quantityFormat),
        wereDefaultTypeChanged:
            !isCurrentDefaultTypeEqualToInitialOne(defaultType),
    };
};

const isTheCurrentOrdersLayoutEqualToTheInitialOne = (
    datasetId,
    ordersOptions
) => {
    const { initialOptions, currentOptions } = ordersOptions;

    const isShouldShowConvertedOrdersEqual = isEqual(
        initialOptions.shouldShowConvertedOrders,
        currentOptions.shouldShowConvertedOrders
    );
    const isShouldHighlightNewOrdersEqual = isEqual(
        initialOptions.shouldHighlightNewOrders,
        currentOptions.shouldHighlightNewOrders
    );
    const isShouldShowDeletedOrdersEqual = isEqual(
        initialOptions.shouldShowDeletedOrders,
        currentOptions.shouldShowDeletedOrders
    );
    const isFilterOptionsEqual = isEqual(
        initialOptions.filterOptions,
        currentOptions.filterOptions
    );
    const isColumnsOptionsEqual = isColumnOptionsEqual(
        datasetId,
        initialOptions.columnOptions,
        currentOptions.columnOptions
    );
    const isRowGroupsSortingEqual = isEqual(
        initialOptions.rowGroupsSorting,
        currentOptions.rowGroupsSorting
    );
    const isShouldOnlyDisplayRumouredOrdersEqual = isEqual(
        initialOptions.shouldOnlyDisplayRumouredOrders,
        currentOptions.shouldOnlyDisplayRumouredOrders
    );

    return (
        isShouldShowConvertedOrdersEqual &&
        isShouldHighlightNewOrdersEqual &&
        isFilterOptionsEqual &&
        isColumnsOptionsEqual &&
        isRowGroupsSortingEqual &&
        isShouldOnlyDisplayRumouredOrdersEqual &&
        isShouldShowDeletedOrdersEqual
    );
};

const isTheCurrentFixturesLayoutEqualToTheInitialOne = (
    datasetId,
    fixturesOptions
) => {
    const { initialOptions, currentOptions } = fixturesOptions;

    const isFilterOptionsEqual = isEqual(
        initialOptions.filterOptions,
        currentOptions.filterOptions
    );
    const isVesselOptionsEqual = isEqual(
        initialOptions.vesselOptions,
        currentOptions.vesselOptions
    );
    const isColumnsOptionsEqual = isColumnOptionsEqual(
        datasetId,
        initialOptions.columnOptions,
        currentOptions.columnOptions
    );
    const isRowGroupsSortingEqual = isEqual(
        initialOptions.rowGroupsSorting,
        currentOptions.rowGroupsSorting
    );
    const isShouldOnlyDisplayRumouredFixturesEqual = isEqual(
        initialOptions.shouldOnlyDisplayRumouredFixtures,
        currentOptions.shouldOnlyDisplayRumouredFixtures
    );
    const isShouldShowDeletedFixturesEqual = isEqual(
        initialOptions.shouldShowDeletedFixtures,
        currentOptions.shouldShowDeletedFixtures
    );

    return (
        isFilterOptionsEqual &&
        isVesselOptionsEqual &&
        isColumnsOptionsEqual &&
        isRowGroupsSortingEqual &&
        isShouldOnlyDisplayRumouredFixturesEqual &&
        isShouldShowDeletedFixturesEqual
    );
};

const isColumnOptionsEqual = (
    datasetId,
    initialColumnOptions,
    currectColumnOptions
) => {
    const dataset = getDataset(datasetId);

    const initialColumnOptionsWithoutUnavailableFields =
        initialColumnOptions.filter(
            (co) =>
                dataset.nonAvailableFields.filter((f) => f === co.colId)
                    .length === 0 && co.hide === false
        );
    const currectColumnOptionsWithoutUnavailableFields =
        currectColumnOptions.filter(
            (co) =>
                dataset.nonAvailableFields.filter((f) => f === co.colId)
                    .length === 0 && co.hide === false
        );

    return isEqual(
        initialColumnOptionsWithoutUnavailableFields,
        currectColumnOptionsWithoutUnavailableFields
    );
};

const sortLayouts = (unsortedLayouts) => {
    const layouts = [...unsortedLayouts];
    return layouts.sort((a, b) => a.name.localeCompare(b.name));
};
