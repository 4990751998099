import { useContext, useState } from 'react';
import { SavedLayoutModel } from 'store/feature/layoutsSlice/models';
import './LayoutManagerPanel.scss';
import {
    layoutsSelectors,
    renameTemplateLayout,
    renameUserLayout,
} from 'store/feature/layoutsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useGridContext from 'hooks/grid/useGridContext';

const LayoutManagerItemInput: React.FC<{
    model: SavedLayoutModel;
    onClose: () => void;
}> = ({ model, onClose }) => {
    const dispatch = useAppDispatch();
    const { gridType } = useGridContext();
    const loading = useAppSelector(layoutsSelectors.loading);
    const [layoutName, setLayoutName] = useState(model.name);

    const handleRenameLayout = (): Promise<any> => {
        if (loading) {
            return Promise.reject();
        }
        if (model.isTemplate) {
            return dispatch(
                renameTemplateLayout({
                    id: model.id,
                    name: layoutName,
                    gridType,
                })
            );
        } else {
            return dispatch(
                renameUserLayout({ id: model.id, name: layoutName, gridType })
            );
        }
    };

    const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleRenameLayout().then(onClose);
    };
    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleRenameLayout().then(onClose);
        } else if (e.key === 'Escape') {
            onClose();
        }
    };

    return (
        <input
            data-cy="layout-name-input"
            autoFocus
            maxLength={40}
            onKeyDown={handleOnKeyDown}
            type="text"
            onChange={(e) => setLayoutName(e.target.value)}
            onBlur={handleOnBlur}
            defaultValue={layoutName}
        />
    );
};

export default LayoutManagerItemInput;
