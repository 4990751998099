//TODO : need to create separate isolation component to reduce duplication with MultipleLocationSelect component

import React from 'react';
import ThirdPartyBrokerInput from './ThirdPartyBrokerInput';
import MultiSelectSeparator from './MultiSelectSeparator';
import DraggableMultiSelectItem from './DraggableMultiSelectItem';
import DroppableMultiSelectSpace from './DroppableMultiSelectSpace';
import cloneDeep from 'lodash/cloneDeep';
import { KEY_TAB, KEY_ENTER } from '../../../constants/keyboardCodes';
import {
    ENTITY_PART_TYPE_GAIN_ACCOUNT_GROUP,
    ENTITY_PART_TYPE_GAIN_ACCOUNT,
    ENTITY_PART_TYPE_SEPARATOR,
    ENTITY_SEPARATOR_CHARACTERS,
    DIRECTION_FORWARD,
    DIRECTION_BACKWARD,
    addPart,
    deleteItemAtPosition,
    normalizeParts,
    dragAndDropPart,
    ENTITY_PART_TYPE_CUSTOM_ACCOUNT,
} from '../../../models/common/EntityPart';
import {
    createBrokerPart,
    createSeparatorBrokerPart,
} from '../../../models/ThirdPartyBrokerPart';
import CreateCompanyForm from 'components/modal/createCompanyForm/CreateCompanyForm';
import validateSelectedThirdPartyCompany from 'components/common/ThirdPartyCompany/ThirdPartyCompanyValidation';

class MultipleThirdPartyBrokerSelect extends React.Component {
    constructor(props) {
        super(props);

        this.brokerSelected = this.brokerSelected.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.separatorEntered = this.separatorEntered.bind(this);
        this.brokerSelect = React.createRef();
        this.handleOnTab = this.handleOnTab.bind(this);
        this.handleOnTabBack = this.handleOnTabBack.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
        this.handleRemovedItem = this.handleRemovedItem.bind(this);
        this.moveCursorLeft = this.moveCursorLeft.bind(this);
        this.moveCursorRight = this.moveCursorRight.bind(this);
        this.onSetCursorPosition = this.onSetCursorPosition.bind(this);
        this.onDraggedItem = this.onDraggedItem.bind(this);

        this.defaultSeperatorCharacter =
            this.props.defaultSeperatorCharacter ||
            ENTITY_SEPARATOR_CHARACTERS[0];
        const parts = this.initializeParts();

        this.state = {
            parts,
            initialValue: this.props.initialChar,
            cursorPos: parts.length,
            isNewCompanyModalOpened: false,
        };
    }

    openNewCompanyModal = () => {
        this.setState({ isNewCompanyModalOpened: true });
    };

    closeCompanyModal = () => {
        this.setState({ isNewCompanyModalOpened: false });
    };

    submitCustomBroker = (company) => {
        const broker = {
            id: company.value,
            type: company.partType,
            name: company.name,
        };
        this.brokerSelected(broker, undefined, false);
        this.setState(
            {
                isNewCompanyModalOpened: false,
            },
            () => this.handleOnEnter()
        );
    };

    initializeParts() {
        let parts = [];

        if (this.props.value) {
            for (const part of this.props.value) {
                const result = addPart(
                    parts,
                    part,
                    undefined,
                    createSeparatorBrokerPart,
                    this.defaultSeperatorCharacter
                );
                parts = result.parts;
            }
        }

        return parts;
    }

    brokerSelected(broker, keyCode, shift) {
        if (
            !validateSelectedThirdPartyCompany(
                this.props.entity,
                undefined,
                broker
            )
        ) {
            return;
        }
        this.setState(
            (prevState) => {
                const parts = cloneDeep(prevState.parts);
                const part = createBrokerPart(broker);
                const { parts: newParts, cursorPos: newPosition } = addPart(
                    parts,
                    part,
                    prevState.cursorPos,
                    createSeparatorBrokerPart,
                    this.defaultSeperatorCharacter
                );
                return {
                    parts: newParts,
                    cursorPos: newPosition,
                    initialValue: null,
                };
            },
            () => {
                switch (keyCode) {
                    case KEY_TAB:
                        if (!shift) {
                            this.handleOnTab();
                        } else {
                            this.handleOnTabBack();
                        }
                        break;

                    case KEY_ENTER:
                        this.handleOnEnter();
                        break;
                    default:
                }
                this.focus();
            }
        );
    }

    separatorEntered(character) {
        this.setState((prevState) => {
            const parts = cloneDeep(prevState.parts);
            const part = createSeparatorBrokerPart(character);
            const newState = addPart(
                parts,
                part,
                prevState.cursorPos,
                createSeparatorBrokerPart,
                this.defaultSeperatorCharacter
            );
            return newState;
        }, this.focus);
    }

    handleOnTab() {
        if (this.props.onTab) {
            this.setState((prevState) => {
                return this.normalizeParts(prevState);
            }, this.props.onTab);
        }
    }

    handleOnTabBack() {
        if (this.props.onTabBack) {
            this.setState((prevState) => {
                return this.normalizeParts(prevState);
            }, this.props.onTabBack);
        }
    }

    handleOnEnter() {
        this.setState((prevState) => {
            return this.normalizeParts(prevState);
        }, this.props.onEnter);
    }

    normalizeParts(prevState) {
        const parts = cloneDeep(prevState.parts);
        return {
            parts: normalizeParts(
                parts,
                createSeparatorBrokerPart,
                this.defaultSeperatorCharacter
            ),
        };
    }

    deleteAtPosition(parts, position, direction) {
        let nextPosition =
            direction === DIRECTION_BACKWARD ? position - 1 : position;

        ({ parts } = deleteItemAtPosition(parts, nextPosition, direction));

        nextPosition =
            direction === DIRECTION_BACKWARD
                ? Math.max(position - 1, 0)
                : position;

        return { parts, cursorPos: nextPosition };
    }

    moveCursorLeft() {
        const newPosition = this.state.cursorPos - 1;
        const nextPosition = newPosition >= 0 ? newPosition : 0;
        this.setState({ cursorPos: nextPosition }, this.focus);
    }

    moveCursorRight() {
        const newPosition =
            this.state.cursorPos === 0 ? 1 : this.state.cursorPos + 1;
        const nextPosition =
            newPosition <= this.state.parts.length
                ? newPosition
                : this.state.parts.length;
        this.setState({ cursorPos: nextPosition }, this.focus);
    }

    handleDelete(direction) {
        let parts = cloneDeep(this.state.parts);
        let position = this.state.cursorPos;

        const newState = this.deleteAtPosition(parts, position, direction);

        this.setState(
            {
                parts: newState.parts,
                cursorPos: newState.cursorPos,
            },
            this.focus
        );
    }

    handleRemovedItem(index) {
        let parts = cloneDeep(this.state.parts);
        let position = index;

        const newState = this.deleteAtPosition(
            parts,
            position,
            DIRECTION_FORWARD
        );

        this.setState(
            {
                parts: newState.parts,
                cursorPos: newState.cursorPos,
            },
            this.focus
        );
    }

    get formattedValue() {
        return this.state.parts.map((part) => {
            return {
                value: part.value,
                name: part.name,
                partType: part.partType,
            };
        });
    }

    focus() {
        if (this.brokerSelect.current != null) {
            this.brokerSelect.current.focus();
        }
    }

    hasFocus() {
        return this.brokerSelect.current.hasFocus();
    }

    onSetCursorPosition(index) {
        this.setState({ cursorPos: index }, this.focus);
    }

    onDraggedItem(sourceIndex, targetIndex) {
        this.setState((prevState) => {
            const newParts = dragAndDropPart(
                prevState.parts,
                sourceIndex,
                targetIndex
            );

            const normalized = normalizeParts(
                newParts,
                createSeparatorBrokerPart,
                this.defaultSeperatorCharacter
            );

            return { parts: normalized, cursorPos: normalized.length };
        });
    }

    render() {
        const { className } = this.props;
        const isLastPosition = this.state.parts.length === this.state.cursorPos;

        const trailingSpace = (index) => (
            <DroppableMultiSelectSpace
                index={index}
                key="trailingSpace"
                onSetCursorPosition={this.onSetCursorPosition}
                onDraggedItem={this.onDraggedItem}
            />
        );

        const items = this.state.parts.map((part, index) => {
            const brokerName =
                part.partType === ENTITY_PART_TYPE_SEPARATOR
                    ? part.value
                    : part.name;

            switch (part.partType) {
                case ENTITY_PART_TYPE_GAIN_ACCOUNT:
                case ENTITY_PART_TYPE_GAIN_ACCOUNT_GROUP:
                case ENTITY_PART_TYPE_CUSTOM_ACCOUNT:
                    return (
                        <DraggableMultiSelectItem
                            key={index}
                            index={index}
                            value={brokerName}
                            onRemovedItem={this.handleRemovedItem}
                            onSetCursorPosition={this.onSetCursorPosition}
                            onDraggedItem={this.onDraggedItem}
                            pill
                            tabIndex="-1"
                        />
                    );
                case ENTITY_PART_TYPE_SEPARATOR:
                    return (
                        <MultiSelectSeparator
                            key={index}
                            index={index}
                            value={brokerName}
                            onSetCursorPosition={this.onSetCursorPosition}
                            onDraggedItem={this.onDraggedItem}
                            onRemovedItem={this.handleRemovedItem}
                            pill
                        />
                    );
                default:
                    throw new Error(
                        `Unknown part type specified: ${part.partType}`
                    );
            }
        });

        const cursor = (
            <div className="multipleLocationSelect-input-container" key="input">
                <div className="multipleLocationSelect-input">
                    <ThirdPartyBrokerInput
                        ref={this.brokerSelect}
                        context={this.props.context}
                        field={this.props.field}
                        onBrokerSelected={this.brokerSelected}
                        seperatorCharacters={ENTITY_SEPARATOR_CHARACTERS}
                        blockedCharacters={this.props.blockedCharacters}
                        onSeparatorEntered={this.separatorEntered}
                        onChange={this.props.onChange}
                        onDelete={this.handleDelete}
                        inputClass={this.props.inputClass}
                        onTab={this.handleOnTab}
                        onTabBack={this.handleOnTabBack}
                        onEnter={this.handleOnEnter}
                        initialChar={this.state.initialValue}
                        cursorPos={this.state.cursorPos}
                        onMoveLeft={this.moveCursorLeft}
                        onMoveRight={this.moveCursorRight}
                        isLastPosition={isLastPosition}
                        downshiftContentClassname="third-party-company-downshift-content"
                        openNewCompanyModal={this.openNewCompanyModal}
                        columnName={this.props.columnName}
                    />
                </div>
            </div>
        );

        items.splice(this.state.cursorPos, 0, cursor);
        if (this.state.cursorPos !== this.state.parts.length) {
            items.push(trailingSpace(this.state.parts.length));
        }

        return (
            <>
                <div className={`multiple-location-select ${className}`}>
                    <div className="multiple-location-select-items">
                        {items}
                    </div>
                </div>
                {this.state.isNewCompanyModalOpened && (
                    <CreateCompanyForm
                        entityId={this.props.entity.id}
                        open={this.state.isNewCompanyModalOpened}
                        onCancel={this.closeCompanyModal}
                        onSubmit={this.submitCustomBroker}
                    />
                )}
            </>
        );
    }
}

export default MultipleThirdPartyBrokerSelect;
