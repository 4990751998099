import { Box, Typography } from '@mui/material';

const EmptyFiltersMenu = () => {
    return (
        <Box p={2}>
            <Typography sx={{ fontSize: '13px' }}>
                There are no filters applied at the moment
            </Typography>
        </Box>
    );
};

export default EmptyFiltersMenu;
