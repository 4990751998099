import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.grey[700],
        boxShadow: theme.shadows[1],
        fontSize: 13,
        textAlign: 'center',
    },
}));

export default LightTooltip;

export type WithLightTooltipProps = {
    Tooltip?: string | React.ReactNode;
    TooltipProps?: TooltipProps;
};
export function WithLightTooltip<P>(
    Component: React.ComponentType<P>
): React.FC<P & WithLightTooltipProps> {
    return (props: P & WithLightTooltipProps) => {
        if (props.Tooltip) {
            return (
                <LightTooltip {...props.TooltipProps} title={props.Tooltip}>
                    <Component {...props} />
                </LightTooltip>
            );
        }
        return <Component {...props} />;
    };
}
