import { gql } from '@apollo/client';

export const SEARCH_THIRD_PARTY_COMPANIES = gql`
    query ThirdPartyCompanies($search: String!) {
        thirdPartyCompanies(searchTerm: $search) {
            id
            type
            name
            parentName
            countryCode
            isNonClient
        }
    }
`;

export const SEARCH_THIRD_PARTY_COMPANY_PARENTS = gql`
    query ThirdPartyCompanyParents($search: String!) {
        thirdPartyCompanyParents(searchTerm: $search) {
            id
            type
            name
            parentName
            countryCode
        }
    }
`;

export const GET_GAIN_ACCOUNTS_BY_IDS = gql`
    query GainAccountsByIds($ids: [String]!) {
        gainAccountsByIds(ids: $ids) {
            id
            parentId
            name
            parentName
            countryCode
            type
        }
    }
`;

export const GET_GAIN_ACCOUNT_GROUPS_BY_IDS = gql`
    query GainAccountGroupsByIds($ids: [String]!) {
        gainAccountGroupsByIds(ids: $ids) {
            id
            parentId
            name
            parentName
            countryCode
            type
        }
    }
`;

export const GET_CUSTOM_ACCOUNTS_BY_IDS = gql`
    query CustomAccountsByIds($ids: [String]!) {
        customAccountsByIds(ids: $ids) {
            id
            parentId
            name
            parentName
            countryCode
            type
        }
    }
`;

export const CREATE_CUSTOM_COMPANY = gql`
    mutation createCustomCompany(
        $createCustomCompanyModel: CreateCustomCompanyInput!
    ) {
        createCustomCompany(
            createCustomCompanyModel: $createCustomCompanyModel
        ) {
            company {
                value
                name
                partType
            }
            error
        }
    }
`;
