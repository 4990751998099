import { ColumnState } from '@ag-grid-enterprise/all-modules';
import { SavedLayoutFragment } from 'api/fragments';

export const LAYOUTS_SLICE_NAME = 'layouts';

export type SavedLayoutModel = SavedLayoutFragment & { isTemplate: boolean };

export interface LayoutGridColumn extends ColumnState {}

export interface BaseGridConfig {
    filterOptions: {};
    columnOptions: LayoutGridColumn[];
    rowGroups: { colId: string; checked: boolean }[];
    rowGroupsSorting: { colId: string; sorting: string[] }[];
}

export interface LayoutCommonSettings {
    directionLogic: { initialState: string; currentState: string };
    quantityFormat: { initialState: string; currentState: string };
    defaultType: { initialState: string; currentState: string };
}

export type Flags<T, V> = {
    [K in keyof T]?: V;
};

export interface LayoutGridConfig<T extends BaseGridConfig> {
    initialOptions: T;
    currentOptions: T;
    hasRecentChanges: boolean;
    hasDifferencies: Flags<T, boolean>;
    collapsedRowGroups: any[];
}

export type LayoutGridConfigMap = {
    fixtures: BaseGridConfig & {
        shouldOnlyDisplayRumouredFixtures: boolean;
        shouldShowDeletedFixtures: boolean;
        vesselOptions: { minDWT: number; maxDWT: number };
    };
    orders: BaseGridConfig & {
        shouldHighlightNewOrders: boolean;
        shouldShowConvertedOrders: boolean;
        shouldOnlyDisplayRumouredOrders: boolean;
        shouldShowDeletedOrders: boolean;
    };
};

export type LayoutGridType = keyof LayoutGridConfigMap;

export type SelectedLayoutModel = {
    selectedLayoutId: string | null;
    name: string;
    isTemplate: boolean;
    areThereAnyUnsavedLayoutChanges: boolean;
    commonSettings: LayoutCommonSettings;
} & {
    [K in LayoutGridType]: LayoutGridConfig<LayoutGridConfigMap[K]>;
};

export interface LayoutsState {
    loading: boolean;
    selectedDatasetId: number | null;
    allLayouts: SavedLayoutModel[];
    selectedLayout: SelectedLayoutModel;
}

export interface BaseLayoutChange {
    gridType: LayoutGridType;
}

export interface LayoutCollapsedRowGroupsChange extends BaseLayoutChange {
    collapsedRowGroups: any[];
}

export interface LayoutGridDelta<
    T,
    GT extends LayoutGridType = LayoutGridType
> {
    gridType: GT;
    changes: T;
}

export interface LayoutChangePayloads {
    filterOptions: LayoutGridDelta<BaseGridConfig['filterOptions']>;
    columnOptions: LayoutGridDelta<BaseGridConfig['columnOptions']>;
}

export type LayoutGridSettingsChange =
    | {
          gridType: 'orders';
          gridSettings: Partial<LayoutGridConfigMap['orders']>;
      }
    | {
          gridType: 'fixtures';
          gridSettings: Partial<LayoutGridConfigMap['fixtures']>;
      };
