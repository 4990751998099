import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateNodeTree from '../../../tools/DateNodeTree';
import FilterTreeView from './FilterTreeView';
import { blankKeyText } from '../../../constants/gridText';
import { FilterGenerator } from './FilterGenerator';
import { TextField } from '@mui/material';
import './DateFilter.scss';
import { IN_DC_VALUE } from '_legacy/constants/CommonConstants';

const TREE_VALUE_FIELD = 'startDate';
const SHORTHAND_FIELD = 'shorthand';
const IS_IN_DC_FIELD = 'isInDC';

class LaycanFilter extends Component {
    constructor(props) {
        super(props);

        this.handleBlanksChange = this.handleBlanksChange.bind(this);
        this.handleAllDatesChange = this.handleAllDatesChange.bind(this);
        this.handleCustomFilterChange =
            this.handleCustomFilterChange.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.handleNodeClicked = this.handleNodeClicked.bind(this);
        this.handleNodeToggled = this.handleNodeToggled.bind(this);
        this.collapseTree = this.collapseTree.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);

        const filters = this.initFilters(props.dates);
        this.tree = filters.tree;

        this.state = {
            showBlanks: true,
            showInDC: true,
            isInDCExists: filters.isInDCExists,
            visibleNodes: props.visibleNodes ? props.visibleNodes : {},
            filterState: this.tree.filterState,
            expanded: [],
            startDate: '',
            endDate: '',
        };
    }

    componentDidMount() {
        this.refreshFilters();
        this.collapseTree();
    }

    initFilters(dates) {
        //get a distinct list of values of this field from the grid and create the tree
        let isInDCExists = false;

        const stringArray = dates ? dates : [];
        this.props.api.forEachNode((node) => {
            if (node.data) {
                stringArray.push(
                    this.treeValueGetter(node.data[this.props.column.colId])
                );

                if (!isInDCExists) {
                    isInDCExists = this.isInDCValueGetter(
                        node.data[this.props.column.colId]
                    );
                }
            }
        });

        return {
            tree: new DateNodeTree(stringArray),
            isInDCExists,
        };
    }

    afterGuiAttached() {
        this.refreshFilters();
    }

    handleNodeToggled(nodes) {
        this.setState({ expanded: nodes });
    }

    collapseTree() {
        this.setState({ expanded: [] });
    }

    refreshFilters() {
        const currentFilterState = this.tree.filterState;
        const filters = this.initFilters();
        this.tree = filters.tree;
        this.tree.setFilterState(currentFilterState);
        const visibleNodes = {};

        const startDate = this.state.startDate
            ? new Date(this.state.startDate)
            : null;
        const endDate = this.state.endDate
            ? new Date(this.state.endDate)
            : null;

        const isRowVisible = this.props.doesRowPassOtherFilter;
        this.props.api.forEachNode((node) => {
            if (node.data && isRowVisible(node)) {
                const primaryKey = this.treeValueGetter(
                    node.data[this.props.column.colId]
                );
                let key = primaryKey;

                const date = key ? new Date(key) : null;

                if (startDate && endDate) {
                    key = date >= startDate && date <= endDate ? key : null;
                } else if (startDate) {
                    key = date >= startDate ? key : null;
                } else if (endDate) {
                    key = date <= endDate ? key : null;
                }

                if (date && !key) {
                    this.tree.setCheckbox(
                        primaryKey,
                        DateNodeTree.States.UNCHECKED
                    );
                }

                const hierarchy = this.tree.getNodeAncestors(key);
                if (hierarchy) {
                    if (startDate || endDate) {
                        this.tree.setCheckbox(key, DateNodeTree.States.CHECKED);
                    }
                    hierarchy.forEach((i) => (visibleNodes[i] = true));
                }
            }
        });

        this.setState({
            isInDCExists: filters.isInDCExists,
            filterState: this.tree.filterState,
            visibleNodes,
        });
    }

    handleBlanksChange(_, checked) {
        if (this.state.startDate || this.state.endDate) {
            this.tree.setCheckbox('_', DateNodeTree.States.CHECKED);
        }

        this.setState(
            {
                filterState: this.tree.filterState,
                showBlanks: checked,
                startDate: '',
                endDate: '',
            },
            () => {
                this.refreshFilters();
                this.props.filterChangedCallback();
            }
        );
    }

    handleAllDatesChange(_, checked) {
        const state = checked
            ? DateNodeTree.States.CHECKED
            : DateNodeTree.States.UNCHECKED;
        this.tree.setCheckbox('_', state);

        this.setState(
            { filterState: this.tree.filterState, startDate: '', endDate: '' },
            () => {
                this.refreshFilters();
                this.props.filterChangedCallback();
            }
        );
    }

    handleCustomFilterChange(_, checked) {
        if (this.state.startDate || this.state.endDate) {
            this.tree.setCheckbox('_', DateNodeTree.States.CHECKED);
        }

        this.setState(
            {
                filterState: this.tree.filterState,
                showInDC: checked,
                startDate: '',
                endDate: '',
            },
            () => {
                this.refreshFilters();
                this.props.filterChangedCallback();
            }
        );
    }

    handleNodeClicked(treeNode, checked) {
        this.tree.setCheckbox(
            treeNode.key,
            checked
                ? DateNodeTree.States.CHECKED
                : DateNodeTree.States.UNCHECKED
        );

        this.setState({ filterState: this.tree.filterState }, () =>
            this.props.filterChangedCallback()
        );
    }

    clearFilter() {
        this.tree.clearFilter();
        const defaultDate = '0001-01-01';

        // We should do that trick for clearing dates because in case of partially filled date
        // inputs will not be cleared (looks like TextField input have internal state, when our state is empty soom, so input could not see changes).
        // But if we populate input with full date then we can clear that input by setting empty value.
        this.setState({ startDate: defaultDate, endDate: defaultDate }, () => {
            this.setState(
                {
                    showBlanks: true,
                    showInDC: true,
                    filterState: this.tree.filterState,
                    startDate: '',
                    endDate: '',
                },
                () => {
                    this.refreshFilters();
                    this.props.filterChangedCallback();
                }
            );
        });
    }

    treeValueGetter(rowValue) {
        let result;

        if (rowValue) {
            result = rowValue[TREE_VALUE_FIELD];
        } else {
            result = rowValue;
        }
        if (result) {
            result = result.slice(0, 10);
        }
        if (rowValue && result) {
            result = result.concat('T00:00:00Z');
        }

        return result;
    }

    shorthandValueGetter = (rowValue) => {
        return rowValue ? rowValue[SHORTHAND_FIELD] : rowValue;
    };

    isInDCValueGetter = (rowValue) => {
        return rowValue ? rowValue[IS_IN_DC_FIELD] : rowValue;
    };

    isShowingAllDates = () => {
        return this.tree.filterState.dateFilterSet.includes(
            DateNodeTree.RootNodeKey
        );
    };

    isFilterActive() {
        return (
            !this.state.showBlanks ||
            !this.isShowingAllDates() ||
            !this.state.showInDC
        );
    }

    doesFilterPass(params) {
        const { showBlanks, showInDC } = this.state;
        const dateAsString = this.treeValueGetter(
            this.props.valueGetter(params.node)
        );
        const isInDC = this.isInDCValueGetter(
            this.props.valueGetter(params.node)
        );

        // shows laycan with shorthand === blank (laycan === null)
        if (showBlanks && !dateAsString && !isInDC) {
            return true;
        }

        // shows laycan with shorthand as date
        if (this.isShowingAllDates() && dateAsString) {
            return true;
        }

        // shows laycan with isInDC property equals true
        if (showInDC && isInDC) return true;

        // checks an appropriate tree date node
        if (dateAsString) {
            return this.tree.isChecked(dateAsString);
        }
    }

    getModel() {
        const { filterState, showBlanks, showInDC, startDate, endDate } =
            this.state;

        return this.isShowingAllDates() && showBlanks && showInDC
            ? null
            : { filterState, showBlanks, showInDC, startDate, endDate };
    }

    setModel(model) {
        if (model) {
            this.tree.setFilterState(model.filterState);
            this.setState(model, () => this.props.filterChangedCallback());
        } else {
            this.clearFilter();
        }
    }

    getBasicFilters() {
        return [
            {
                type: 'checkbox',
                checked: this.state.showBlanks,
                onChange: this.handleBlanksChange,
                label: blankKeyText,
            },
            {
                type: 'checkbox',
                checked: this.isShowingAllDates(),
                onChange: this.handleAllDatesChange,
                label: 'ALL DATES',
            },
        ];
    }

    getCustomFilters() {
        const { isInDCExists } = this.state;

        return isInDCExists
            ? [
                  {
                      type: 'checkbox',
                      checked: this.state.showInDC,
                      onChange: this.handleCustomFilterChange,
                      label: IN_DC_VALUE,
                  },
              ]
            : [];
    }

    setStartDate(event) {
        const shouldBeChecked =
            event.target.value === '' && this.state.endDate === '';

        this.setState(
            {
                startDate: event.target.value,
                showBlanks: shouldBeChecked,
                showInDC: shouldBeChecked,
            },
            () => {
                this.refreshFilters();
                this.props.filterChangedCallback();
            }
        );
    }

    setEndDate(event) {
        const shouldBeChecked =
            event.target.value === '' && this.state.startDate === '';

        this.setState(
            {
                endDate: event.target.value,
                showBlanks: shouldBeChecked,
                showInDC: shouldBeChecked,
            },
            () => {
                this.refreshFilters();
                this.props.filterChangedCallback();
            }
        );
    }

    render() {
        const visibleNodes = this.state.visibleNodes;
        const basicFilters = this.getBasicFilters();
        const customFilters = this.getCustomFilters();

        const elements = [
            [...basicFilters, ...customFilters],
            <div>
                <form noValidate>
                    <TextField
                        id="date"
                        label="From"
                        type="date"
                        value={this.state.startDate}
                        onChange={this.setStartDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </form>
                <form noValidate style={{ paddingTop: '5px' }}>
                    <TextField
                        id="date"
                        label="To"
                        type="date"
                        value={this.state.endDate}
                        onChange={this.setEndDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </form>
            </div>,
            <FilterTreeView
                tree={this.tree}
                visibleNodes={visibleNodes}
                expanded={this.state.expanded}
                onNodeClicked={this.handleNodeClicked}
                onNodeToggle={this.handleNodeToggled}
            />,
        ];

        return <FilterGenerator elements={elements} clear={this.clearFilter} />;
    }
}

LaycanFilter.propTypes = {
    filterChangedCallback: PropTypes.func.isRequired,
};

export default LaycanFilter;
