import FilterLabels from './FilterLabels';
import WithFilters from './HOCs/WithFilters';
import { FormGroup, FormLabel } from '@mui/material';

const SavedFiltersForm = ({
    savedFilters,
    setSavedFilters,
    handleFilterChange,
}) => {
    const handleSavedFilterChange = (event) =>
        handleFilterChange(event, savedFilters, setSavedFilters);

    return savedFilters.length > 0 ? (
        <>
            {' '}
            <FormLabel
                component="legend"
                sx={{
                    marginBottom: '5px',
                    color: '#0000008A !important',
                    fontFamily: 'Cera GR Medium',
                    fontSize: '13px',
                }}
            >
                SAVED FILTERS
            </FormLabel>
            <FormGroup sx={{ marginLeft: '12px' }}>
                <FilterLabels
                    filters={savedFilters}
                    handleFilterChange={handleSavedFilterChange}
                />
            </FormGroup>{' '}
        </>
    ) : null;
};

export default WithFilters(SavedFiltersForm);
