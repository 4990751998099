import React from 'react';
import Header from '../Header';
import GroupColumns from './GroupColumns';
import GroupColumnsSorting from './GroupColumnsSorting';
import { Divider, ListItem, Collapse, Box } from '@mui/material';
import SetDefaultGrouppingButton from 'components/grid-sidebar/SetDefaultGrouppingButton';

const GroupingToolPanel = (props) => {
    const [isRowGroupsOpen, setIsRowGroupsOpen] = React.useState(true);
    const [isRowGroupsSortingOpen, setIsRowGroupsSortingOpen] =
        React.useState(true);

    const handleRowGroupsClick = () => {
        setIsRowGroupsOpen(!isRowGroupsOpen);
    };

    const handleRowGroupsSortingClick = () => {
        setIsRowGroupsSortingOpen(!isRowGroupsSortingOpen);
    };

    return (
        <>
            <Box className="ag-unselectable ag-column-drop ag-column-drop-vertical ag-last-column-drop">
                <ListItem button onClick={handleRowGroupsClick}>
                    <Header
                        name="Row Groups"
                        icon="ag-icon-columns"
                        isOpen={isRowGroupsOpen}
                    />
                </ListItem>
                <Collapse in={isRowGroupsOpen} timeout="auto" unmountOnExit>
                    <GroupColumns {...props} />
                </Collapse>
                <Divider variant="middle" />
                <ListItem button onClick={handleRowGroupsSortingClick}>
                    <Header
                        name="Row Group Ordering"
                        icon="ag-icon-group"
                        isOpen={isRowGroupsSortingOpen}
                    />
                </ListItem>
                <Collapse
                    in={isRowGroupsSortingOpen}
                    timeout="auto"
                    unmountOnExit
                >
                    <GroupColumnsSorting {...props} />
                </Collapse>
            </Box>
            {/* Uncomment to enable SetDefaultGroupping functionality */}
            {/* <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <SetDefaultGrouppingButton />
            </Box> */}
        </>
    );
};

export default GroupingToolPanel;
