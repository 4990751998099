import { Button } from '@mui/material';
import FortDialog from '../FortDialog';

interface SwitchLayoutDialogProps {
    open: boolean;
    onCancel: () => void;
    onContinue: () => void;
    onSaveAs: () => void;
}

const SwitchLayoutDialog: React.FC<SwitchLayoutDialogProps> = ({
    open,
    onCancel,
    onContinue,
    onSaveAs,
}) => {
    return (
        <FortDialog
            idx="confirm_switch_layout"
            open={open}
            onCancel={onCancel}
            text="Your layout has unsaved changes, if you continue your changes will be lost."
            actions={
                <>
                    <Button autoFocus variant="contained" onClick={onCancel}>
                        CANCEL
                    </Button>
                    <Button variant="outlined" onClick={onContinue}>
                        CONTINUE
                    </Button>
                    <Button variant="outlined" onClick={onSaveAs}>
                        SAVE AS
                    </Button>
                </>
            }
        />
    );
};

export default SwitchLayoutDialog;
