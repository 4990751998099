import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CargoesNodeTree from '../../../tools/CargoesNodeTree';
import {
    ENTITY_PART_TYPE_CUSTOM,
    ENTITY_PART_TYPE_KNOWN,
    ENTITY_PART_TYPE_SEPARATOR,
    ENTITY_PART_TYPE_PLACEHOLDER,
} from '../../../models/common/EntityPart';
import { CargoApi } from '../../../api/cargoApi';
import FilterTreeView from './FilterTreeView';
import { blankKeyText } from '../../../constants/gridText';
import { FilterGenerator } from './FilterGenerator';
import { KEY_ENTER } from '../../../../_legacy/constants/keyboardCodes';

class CargoesFilter extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.isSearchMatched = this.isSearchMatched.bind(this);
        this.handleBlanksChange = this.handleBlanksChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleAllCargoesChange = this.handleAllCargoesChange.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.handleNodeClicked = this.handleNodeClicked.bind(this);
        this.handleNodeToggled = this.handleNodeToggled.bind(this);
        this.collapseTree = this.collapseTree.bind(this);
        this.refreshFilterTree = this.refreshFilterTree.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

        this.tree = this.createTree();

        this.state = {
            showBlanks: true,
            visibleNodes: props.visibleNodes ? props.visibleNodes : {},
            filterState: this.tree.filterState,
            expanded: [],
            filterText: '',
        };
    }

    componentDidMount() {
        setTimeout(this.refreshFilterTree, 100);
        this.collapseTree(true);
    }

    afterGuiAttached() {
        this.refreshFilterTree();
    }

    createTree(customCargoes, knownCargoes, placeholderCargoes) {
        return new CargoesNodeTree(
            customCargoes,
            knownCargoes,
            placeholderCargoes
        );
    }

    handleNodeToggled(nodes) {
        this.setState({ expanded: nodes });
    }

    collapseTree() {
        this.setState({ expanded: [] });
    }

    getCargoes(partType) {
        const cargoes = [];
        this.props.api.forEachNode((node) => {
            if (node.data && this.props.doesRowPassOtherFilter(node)) {
                const cargoParts = this.valueGetter(
                    node.data[this.props.column.colId]
                );
                if (cargoParts) {
                    cargoParts
                        .filter((cp) => cp.partType === partType)
                        .forEach((cp) => cargoes.push(cp.value));
                }
            }
        });
        return cargoes;
    }

    refreshCargoes(cargoes, visibleNodes, prefix) {
        cargoes
            .filter((c) =>
                c.toLowerCase().includes(this.state.filterText.toLowerCase())
            )
            .forEach((cargo) => {
                const hierarchy = this.tree.getNodeAncestors(prefix + cargo);
                if (hierarchy) {
                    hierarchy.forEach((i) => (visibleNodes[i] = true));
                }
            });
    }

    isSearchMatched(cargo) {
        const text = this.state.filterText.toLowerCase();
        return (
            cargo.aliases.some((a) => a.toLowerCase() === text) ||
            cargo.fullName.toLowerCase().includes(text)
        );
    }

    refreshKnownCargoes(cargoes, visibleNodes) {
        cargoes.filter(this.isSearchMatched).forEach((cargo) => {
            const hierarchy = this.tree.getNodeAncestors(cargo.id);

            if (hierarchy) {
                let nodes = this.state.expanded;
                if (this.state.filterText !== '') {
                    for (const node of hierarchy) {
                        if (!nodes.includes(node)) {
                            nodes.push(node);
                        }
                    }
                }

                hierarchy.forEach((i) => (visibleNodes[i] = true));
            }
        });
    }

    async refreshFilterTree() {
        const knownCargoIds = this.getCargoes(ENTITY_PART_TYPE_KNOWN);
        const customCargoes = this.getCargoes(ENTITY_PART_TYPE_CUSTOM);
        const placeholderCargoes = this.getCargoes(
            ENTITY_PART_TYPE_PLACEHOLDER
        );
        const knownCargoes = await CargoApi.getWithParentsByIds(knownCargoIds);

        const currentFilterState = this.state.filterState;
        this.tree = this.createTree(
            customCargoes,
            knownCargoes,
            placeholderCargoes
        );
        this.tree.setFilterState(currentFilterState);
        const visibleNodes = {};

        this.refreshCargoes(
            customCargoes,
            visibleNodes,
            CargoesNodeTree.CustomCargoPrefix
        );
        this.refreshCargoes(
            placeholderCargoes,
            visibleNodes,
            CargoesNodeTree.PlaceholderCargoPrefix
        );
        this.refreshKnownCargoes(knownCargoes, visibleNodes);

        if (this.state.filterText.toLowerCase() === '') {
            this.collapseTree();
        }

        this.setState(
            {
                filterState: currentFilterState,
                visibleNodes: visibleNodes,
            },
            () => this.props.filterChangedCallback()
        );
    }

    handleBlanksChange(_, checked) {
        this.setState({ showBlanks: checked }, () =>
            this.props.filterChangedCallback()
        );
    }

    handleSearchChange(event) {
        this.setState({ filterText: event.target.value.trimStart() }, () => {
            this.refreshFilterTree();
            this.props.filterChangedCallback();
        });
    }

    handleKeyDown(event) {
        if (event.keyCode === KEY_ENTER) {
            this.filterGridByVisibleNodes();
        }
    }

    filterGridByVisibleNodes() {
        this.tree.filterByVisibleNodes(this.tree, this.state.visibleNodes);

        this.setState(
            {
                showBlanks: false,
                filterState: this.tree.filterState,
            },
            () => {
                this.props.filterChangedCallback();
            }
        );
    }

    handleAllCargoesChange(_, checked) {
        const state = checked
            ? CargoesNodeTree.States.CHECKED
            : CargoesNodeTree.States.UNCHECKED;
        this.tree.setCheckbox('_', state);

        this.setState({ filterState: this.tree.filterState }, () => {
            this.props.filterChangedCallback();
        });
    }

    handleNodeClicked(treeNode, checked) {
        let { showBlanks } = this.state;

        this.tree.setCheckbox(
            treeNode.key,
            checked
                ? CargoesNodeTree.States.CHECKED
                : CargoesNodeTree.States.UNCHECKED
        );

        this.setState({ showBlanks, filterState: this.tree.filterState }, () =>
            this.props.filterChangedCallback()
        );
    }

    clearFilter() {
        this.tree.clearFilter();
        this.setState(
            {
                showBlanks: true,
                filterState: this.tree.filterState,
                filterText: '',
            },
            () => {
                this.refreshFilterTree();
                this.props.filterChangedCallback();
            }
        );
    }

    valueGetter(rowValue) {
        return this.props.fieldName && rowValue
            ? rowValue[this.props.fieldName]
            : rowValue;
    }

    isShowingAllCargoes = () => {
        return this.tree.filterState.entityFilterSet.includes(
            CargoesNodeTree.RootNodeKey
        );
    };

    isFilterActive() {
        return !this.state.showBlanks || !this.isShowingAllCargoes();
    }

    doesFilterPass(params) {
        const cargoParts = this.valueGetter(
            this.props.valueGetter(params.node)
        )?.filter((cp) => cp.partType !== ENTITY_PART_TYPE_SEPARATOR);

        if ((!cargoParts || cargoParts.length === 0) && this.state.showBlanks) {
            return true;
        }

        if (cargoParts && cargoParts.length > 0) {
            if (this.isShowingAllCargoes()) {
                return true;
            }

            for (const part of cargoParts) {
                const keyNode =
                    part.partType === ENTITY_PART_TYPE_CUSTOM
                        ? CargoesNodeTree.CustomCargoPrefix + part.value
                        : part.partType === ENTITY_PART_TYPE_PLACEHOLDER
                        ? CargoesNodeTree.PlaceholderCargoPrefix + part.value
                        : part.value;
                if (this.tree.isChecked(keyNode)) {
                    return true;
                }
            }
        }

        return false;
    }

    getModel() {
        const { filterState, showBlanks } = this.state;
        return this.isShowingAllCargoes() && showBlanks
            ? null
            : { filterState, showBlanks };
    }

    setModel(model) {
        if (model) {
            this.tree.setFilterState(model.filterState);
            this.setState(
                {
                    filterState: model.filterState,
                    showBlanks: model.showBlanks,
                },
                () => this.refreshFilterTree()
            );
        } else {
            this.clearFilter();
        }
    }

    render() {
        const elements = [
            <input
                ref={this.inputRef}
                style={{ marginBottom: '10px' }}
                className="ag-input-field-input ag-text-field-input"
                type="text"
                value={this.state.filterText}
                onChange={this.handleSearchChange}
                onKeyDown={this.handleKeyDown}
                placeholder="Search..."
                autoFocus
            />,
            [
                {
                    type: 'checkbox',
                    checked: this.state.showBlanks,
                    onChange: this.handleBlanksChange,
                    label: blankKeyText,
                },
                {
                    type: 'checkbox',
                    checked: this.isShowingAllCargoes(),
                    onChange: this.handleAllCargoesChange,
                    label: 'ALL CARGOES',
                },
            ],
            <FilterTreeView
                tree={this.tree}
                visibleNodes={this.state.visibleNodes}
                expanded={this.state.expanded}
                onNodeClicked={this.handleNodeClicked}
                onNodeToggle={this.handleNodeToggled}
            />,
        ];

        return <FilterGenerator elements={elements} clear={this.clearFilter} />;
    }
}

CargoesFilter.propTypes = {
    filterChangedCallback: PropTypes.func.isRequired,
};

export default CargoesFilter;
