import { Button } from '@mui/material';
import FortDialog from '../FortDialog';

interface DeleteTemplateLayoutConfirmationDialogProps {
    open: boolean;
    onCancel: () => void;
    onDelete: () => void;
}

const DeleteTemplateLayoutConfirmationDialog: React.FC<
    DeleteTemplateLayoutConfirmationDialogProps
> = ({ open, onCancel, onDelete }) => {
    return (
        <FortDialog
            idx="delete_template_layout"
            open={open}
            onCancel={onCancel}
            text="Deleting this template layout will affect all users in this dataset."
            actions={
                <>
                    <Button
                        onClick={onCancel}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        {'Cancel'}
                    </Button>
                    <Button onClick={onDelete} variant="outlined" color="error">
                        {'Delete'}
                    </Button>
                </>
            }
        />
    );
};

export default DeleteTemplateLayoutConfirmationDialog;
