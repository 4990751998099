import { gql } from '@apollo/client';

export interface UserSettingsFragment {
    newThemeEnabled: boolean;
    useCondensedView: boolean;
    useCompactDensityView: boolean;
    layoutsUserSectionExpanded: boolean;
    layoutsAdminTemplateSectionExpanded: boolean;
    layoutsTemplateSectionExpanded: boolean;
}

export const USER_SETTINGS_FRAGMENT = gql`
    fragment UserSettingsFragment on UserSettings {
        newThemeEnabled
        useCondensedView
        useCompactDensityView
        layoutsUserSectionExpanded
        layoutsAdminTemplateSectionExpanded
        layoutsTemplateSectionExpanded
    }
`;
