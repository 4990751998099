import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { isNewThemeEnabled } from '_legacy/reducers/userSelectors';
import { connect } from 'react-redux';

const SwitchWithSubLabel = ({
    isChecked,
    onChange,
    label,
    subLabel,
    newThemeEnabled,
}) => {
    const labelClassName = newThemeEnabled
        ? 'new-ui-moreButtons-toggle-label'
        : 'moreButtons-toggle-label';

    const subLabelClassName = newThemeEnabled
        ? 'new-ui-moreButtons-toggle-sub-label'
        : 'moreButtons-toggle-sub-label';

    const style = {
        label: {
            width: '100%',
            height: '24px',
            margin: 0,
            paddingTop: '22px',
            paddingBottom: '24px',
        },
    };

    return (
        <>
            <FormControlLabel
                style={style.label}
                label={
                    <div>
                        <Typography className={labelClassName}>
                            {label}
                        </Typography>
                        <Typography className={subLabelClassName}>
                            {subLabel}
                        </Typography>
                    </div>
                }
                labelPlacement="start"
                control={
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        width="100%"
                        marginBottom="15px"
                    >
                        <Switch
                            checked={isChecked}
                            onChange={onChange}
                            name="checked"
                            color="tertiary"
                        />
                    </Box>
                }
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    newThemeEnabled: isNewThemeEnabled(state),
});

export default connect(mapStateToProps)(SwitchWithSubLabel);
