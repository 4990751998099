import FilterLabels from './FilterLabels';
import WithFilters from './HOCs/WithFilters';
import { Button, Divider, FormGroup, FormLabel } from '@mui/material';

const UnsavedFiltersForm = ({
    unsavedFilters,
    setUnsavedFilters,
    handleFilterChange,
    handleClose,
    clearUnsavedFilters,
}) => {
    const handleUnsavedFilterChange = (event) =>
        handleFilterChange(event, unsavedFilters, setUnsavedFilters);

    const handleClearUnsavedFilters = () => {
        clearUnsavedFilters(unsavedFilters);
        handleClose();
    };

    return unsavedFilters.length > 0 ? (
        <>
            <FormLabel
                component="legend"
                sx={{
                    marginTop: '10px',
                    marginBottom: '5px',
                    color: '#0000008A !important',
                    fontFamily: 'Cera GR Medium',
                    fontSize: '13px',
                }}
            >
                UNSAVED FILTERS
            </FormLabel>
            <FormGroup sx={{ marginLeft: '12px' }}>
                <FilterLabels
                    filters={unsavedFilters}
                    handleFilterChange={handleUnsavedFilterChange}
                />
            </FormGroup>
            <Divider />
            <Button
                variant="contained"
                color="primary"
                sx={{
                    marginTop: '10px',
                }}
                onClick={handleClearUnsavedFilters}
            >
                CLEAR UNSAVED FILTERS
            </Button>
        </>
    ) : null;
};

export default WithFilters(UnsavedFiltersForm);
