import LightTooltip from 'components/common/LightTooltip';

const IndividualLabelRenderer = (props) => {
    let tooltip = `${props.documentName} will only be visible to the creator.`;

    return (
        <LightTooltip title={tooltip} placement="right" arrow>
            <div>Individual {props.documentName}</div>
        </LightTooltip>
    );
};

export default IndividualLabelRenderer;
