import { gql } from '@apollo/client';
import {
    GROUP_FRAGMENT,
    GroupFragment,
    SAVED_LAYOUT_FRAGMENT,
    SavedLayoutFragment,
    USER_SETTINGS_FRAGMENT,
    UserSettingsFragment,
} from 'api/fragments';
import graphQlClient from './graphQlClient';
import _ from 'lodash';

export module UserApi {
    export interface UserInfoResult {
        id: string;
        userId: number;
        groups: GroupFragment[];
        permissions: { groupId: string; canWrite: boolean }[];
        userLayouts: SavedLayoutFragment[];
        sharedLayouts: SavedLayoutFragment[];
        uiSettings: UserSettingsFragment;
    }

    const USER_INFO_QUERY = gql`
        ${GROUP_FRAGMENT}
        ${SAVED_LAYOUT_FRAGMENT}
        ${USER_SETTINGS_FRAGMENT}

        query UserInfo {
            me {
                id
                userId
                groups {
                    ...GroupFragment
                }
                permissions {
                    groupId
                    canWrite
                }
                userLayouts {
                    ...SavedLayoutFragment
                }
                sharedLayouts {
                    ...SavedLayoutFragment
                }
                uiSettings {
                    ...UserSettingsFragment
                }
            }
        }
    `;

    export async function fetchUserInfo(): Promise<UserInfoResult> {
        const result = await graphQlClient().query<{ me: UserInfoResult }>({
            query: USER_INFO_QUERY,
        });
        return result.data.me;
    }

    const UPDATE_USER_SETTINGS_MUTATION = gql`
        ${USER_SETTINGS_FRAGMENT}

        mutation ChangeLayoutMenuSettings(
            $userExpanded: Boolean!
            $adminExpanded: Boolean!
            $templateExpanded: Boolean!
        ) {
            changeLayoutMenuSettings(
                userExpanded: $userExpanded
                adminExpanded: $adminExpanded
                templateExpanded: $templateExpanded
            ) {
                ...UserSettingsFragment
            }
        }
    `;

    export async function updateUserSettings(
        userExpanded: boolean,
        adminExpanded: boolean,
        templateExpanded: boolean
    ): Promise<
        Pick<
            UserSettingsFragment,
            | 'layoutsUserSectionExpanded'
            | 'layoutsAdminTemplateSectionExpanded'
            | 'layoutsTemplateSectionExpanded'
        >
    > {
        const result = await graphQlClient().mutate<{
            changeLayoutMenuSettings: UserSettingsFragment;
        }>({
            mutation: UPDATE_USER_SETTINGS_MUTATION,
            variables: {
                userExpanded,
                adminExpanded,
                templateExpanded,
            },
        });
        if (!result.data) {
            throw new Error('Failed to update user settings');
        }

        return _.pick(result.data.changeLayoutMenuSettings, [
            'layoutsUserSectionExpanded',
            'layoutsAdminTemplateSectionExpanded',
            'layoutsTemplateSectionExpanded',
        ]);
    }
}
