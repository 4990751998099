import { Button, ButtonOwnProps, styled } from '@mui/material';
import { SavedLayoutModel } from 'store/feature/layoutsSlice/models';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userSelectors } from 'store/feature/userSlice';
import CreateLayoutDialog from 'components/modal/layouts/dialogs/CreateLayoutDialog';
import { useState } from 'react';
import {
    layoutsSelectors,
    revertLayout,
    updateTemplateLayout,
    updateUserLayout,
} from 'store/feature/layoutsSlice';
import UpdateTemplateLayoutConfirmationDialog from 'components/modal/layouts/dialogs/UpdateTemplateLayoutConfirmationDialog';
import { WithLightTooltip } from './LightTooltip';
import useGridContext from 'hooks/grid/useGridContext';

const StyledButtonStack = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
}));

const ButtonWithTooltip = WithLightTooltip(Button);

interface LayoutControlsProps {
    model: Pick<SavedLayoutModel, 'id' | 'name' | 'isTemplate'>;
    size?: 'small' | 'medium' | 'large';
    primaryColor?: ButtonOwnProps['color'];
    secondaryColor?: ButtonOwnProps['color'];
    showTooltips?: boolean;
}

const UserLayoutControls: React.FC<LayoutControlsProps> = ({
    size = 'small',
    primaryColor = 'primary',
    secondaryColor = 'primary',
    showTooltips = true,
}) => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(layoutsSelectors.loading);
    const selectedLayoutId = useAppSelector(layoutsSelectors.selectedLayoutId);

    const { gridType } = useGridContext();
    const [showDialog, setShowDialog] = useState(false);

    const handleRevert = () => {
        dispatch(revertLayout());
    };

    const handleSave = () => {
        dispatch(updateUserLayout({ gridType }));
    };

    return (
        <StyledButtonStack onClick={(e) => e.stopPropagation()}>
            <ButtonWithTooltip
                className="layout-controls__revert"
                Tooltip={
                    showTooltips
                        ? 'Revert back to the previously saved layout'
                        : null
                }
                variant="outlined"
                color={secondaryColor}
                size={size}
                onClick={handleRevert}
                disabled={loading || !selectedLayoutId}
            >
                Revert
            </ButtonWithTooltip>
            <ButtonWithTooltip
                className="layout-controls__save-as"
                Tooltip={
                    showTooltips ? 'Save current layout as a new one' : null
                }
                variant="outlined"
                color={secondaryColor}
                size={size}
                onClick={() => setShowDialog(true)}
                disabled={loading}
            >
                Save As
            </ButtonWithTooltip>
            <ButtonWithTooltip
                className="layout-controls__save"
                Tooltip={showTooltips ? 'Save current layout' : null}
                variant="contained"
                color={primaryColor}
                size={size}
                onClick={handleSave}
                disabled={loading || !selectedLayoutId}
            >
                Save
            </ButtonWithTooltip>
            <CreateLayoutDialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
            />
        </StyledButtonStack>
    );
};

const TemplateLayoutControls: React.FC<LayoutControlsProps> = ({
    size = 'small',
    primaryColor = 'primary',
    secondaryColor = 'primary',
    showTooltips = true,
}) => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(layoutsSelectors.loading);
    const isAdmin = useAppSelector(userSelectors.isAdmin);

    const { gridType } = useGridContext();
    const [showDialog, setShowDialog] = useState<'saveAs' | 'save' | false>(
        false
    );

    const handleRevert = () => {
        dispatch(revertLayout());
    };

    const handleSave = () => {
        dispatch(updateTemplateLayout({ gridType }));
    };

    return (
        <StyledButtonStack onClick={(e) => e.stopPropagation()}>
            <ButtonWithTooltip
                className="layout-controls__revert"
                Tooltip={
                    showTooltips
                        ? 'Revert back to the previously saved layout'
                        : null
                }
                variant="outlined"
                color={secondaryColor}
                size={size}
                onClick={handleRevert}
                disabled={loading}
            >
                Revert
            </ButtonWithTooltip>
            {isAdmin && (
                <ButtonWithTooltip
                    className="layout-controls__save"
                    Tooltip={showTooltips ? 'Save current layout' : null}
                    variant="outlined"
                    color={secondaryColor}
                    size={size}
                    onClick={() => setShowDialog('save')}
                    disabled={loading}
                >
                    Save
                </ButtonWithTooltip>
            )}
            <ButtonWithTooltip
                className="layout-controls__save-as"
                Tooltip={
                    showTooltips ? 'Save current layout as a new one' : null
                }
                variant="contained"
                color={primaryColor}
                size={size}
                onClick={() => setShowDialog('saveAs')}
                disabled={loading}
            >
                Save As
            </ButtonWithTooltip>
            <CreateLayoutDialog
                open={showDialog === 'saveAs'}
                onClose={() => setShowDialog(false)}
            />
            <UpdateTemplateLayoutConfirmationDialog
                open={showDialog === 'save'}
                onCancel={() => setShowDialog(false)}
                onContinue={() => {
                    setShowDialog(false);
                    handleSave();
                }}
                onSaveAs={() => setShowDialog('saveAs')}
            />
        </StyledButtonStack>
    );
};

const LayoutControls: React.FC<LayoutControlsProps> = (props) => {
    return props.model.isTemplate ? (
        <TemplateLayoutControls {...props} />
    ) : (
        <UserLayoutControls {...props} />
    );
};

export default LayoutControls;
