import React from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import isEqual from 'lodash/isEqual';
import GroupColumn from './GroupColumn';
import { FIXTURE_GRID_TYPE } from '_legacy/constants/gridTypes';
import getHeadingTitle from '_legacy/services/headingsServices/getHeadingTitle';

const GroupColumns = (props) => {
    const changeVisibility = (id) => {
        const rowGroupIndex = props.currentOptions.rowGroups.findIndex(
            (c) => c.colId === id
        );

        const newRowGroups = update(props.currentOptions.rowGroups, {
            [rowGroupIndex]: {
                $apply: function (x) {
                    return { ...x, checked: !x.checked };
                },
            },
        });

        const newRowGroupsSorting = updateRowGroupsSorting(id, newRowGroups);

        updateLayout(newRowGroups, newRowGroupsSorting);
    };

    const moveGroupRow = (dragIndex, hoverIndex) => {
        const newRowGroups = update(props.currentOptions.rowGroups, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, props.currentOptions.rowGroups[dragIndex]],
            ],
        });

        updateLayout(newRowGroups, props.currentOptions.rowGroupsSorting);
    };

    const updateLayout = (rowGroups, rowGroupsSorting) => {
        const currentLayoutOptions = {
            ...props.currentOptions,
            rowGroups,
            rowGroupsSorting,
        };

        props.onGridOptionsChanged(currentLayoutOptions);
    };

    const updateRowGroupsSorting = (colId, rowGroups) => {
        const { datasetId, commonSettings } = props;
        const rowGropSortingIndex =
            props.currentOptions.rowGroupsSorting.findIndex(
                (group) => group.colId === colId
            );

        if (rowGropSortingIndex > -1) {
            const isChecked = rowGroups.find(
                (group) => group.colId === colId
            ).checked;
            const defaultSorting = props.getDefaultRowGroupsSorting(
                datasetId,
                commonSettings.directionLogic.currentState
            );
            const colIdDefaultSorting = defaultSorting.find(
                (group) => group.colId === colId
            );

            if (!isChecked && colIdDefaultSorting) {
                const newRowGroupsSorting = update(
                    props.currentOptions.rowGroupsSorting,
                    {
                        [rowGropSortingIndex]: {
                            $apply: function (x) {
                                return {
                                    ...x,
                                    sorting: colIdDefaultSorting.sorting,
                                };
                            },
                        },
                    }
                );

                return newRowGroupsSorting;
            }
        }

        return props.currentOptions.rowGroupsSorting;
    };

    const renderGroupColumn = (rowGroup, index) => {
        const isFixtureGridType = props.gridType === FIXTURE_GRID_TYPE;
        const header = getHeadingTitle(
            props.datasetId,
            rowGroup.colId,
            isFixtureGridType
        );

        return (
            <GroupColumn
                index={index}
                key={rowGroup.colId}
                id={rowGroup.colId}
                header={header}
                checked={rowGroup.checked}
                moveGroupRow={moveGroupRow}
                changeVisibility={changeVisibility}
            />
        );
    };

    return (
        <div className="ag-column-drop-list ag-column-drop-vertical-list">
            {props.currentOptions.rowGroups.map((rowGroup, i) =>
                renderGroupColumn(rowGroup, i)
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    const props = {
        commonSettings: state.layouts.selectedLayout.commonSettings,
    };

    return props;
};

export default connect(mapStateToProps)(GroupColumns);
