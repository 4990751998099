import { Button } from '@mui/material';
import FortDialog from '../FortDialog';

interface UpdateLayoutConfirmationDialogProps {
    open: boolean;
    onCancel: () => void;
    onContinue: () => void;
    onSaveAs: () => void;
}

const UpdateTemplateLayoutConfirmationDialog: React.FC<
    UpdateLayoutConfirmationDialogProps
> = ({ open, onCancel, onContinue, onSaveAs }) => {
    return (
        <FortDialog
            idx="update_template_layout"
            open={open}
            onCancel={onCancel}
            text="Changes to the template layout will affect all users in this dataset."
            actions={
                <>
                    <Button
                        onClick={onCancel}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        {'Cancel'}
                    </Button>
                    <Button
                        onClick={onContinue}
                        variant="outlined"
                        color="primary"
                    >
                        {'Continue'}
                    </Button>
                    <Button
                        onClick={onSaveAs}
                        variant="outlined"
                        color="primary"
                    >
                        {'Save As'}
                    </Button>
                </>
            }
        />
    );
};

export default UpdateTemplateLayoutConfirmationDialog;
