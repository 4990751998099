import { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    ClickAwayListener,
    Divider,
    MenuItem,
    styled,
} from '@mui/material';
import CheckedIcon from '../icons/CheckedIcon';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import ToolbarButton from './ToolbarButton';

const StyledMenuBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '40px',
    right: 0,
    zIndex: 500,
    background: 'white',
    padding: theme.spacing(0),
    margin: 0,
    textTransform: 'none',
    borderRadius: '4px',
    border: '1px solid rgba(34, 36,38,.15)',
    boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    color: theme.palette.common.black,
    '& .MuiDivider-root': {
        margin: '0 !important',
    },
    '& .AccordionDropdownMenu-menuItem': {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        overflow: 'visible',
        position: 'relative',
    },
    '& .AccordionDropdownMenu-subMenuItem': {
        extend: 'menuItem',
        paddingLeft: 0,
        width: '100%',
    },
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    border: 0,
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
        margin: '0 !important',
        minHeight: '45px !important',
        padding: 0,
        '& .MuiIconButton-root': {
            marginRight: 0,
            padding: '12px !important',
        },
    },
    '& .MuiAccordionSummary-content': {
        margin: 0,
        '&.Mui-expanded': {
            padding: 0,
            margin: '0 !important',
            border: 0,
            boxShadow: 'none',
        },
    },
    '& .MuiAccordionDetails-root': {
        margin: 0,
        padding: 0,
        border: 0,
    },
}));

const StyledOverlay = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 300,
    backgroundColor: 'transparent !important',
}));

const StyledCaption = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    width: '100%',
}));

const AccordionDropdownMenu = ({
    menuItems,
    triggerButtonLabel,
    triggerButtonId,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleTriggerButtonClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleClickAway = () => {
        setIsMenuOpen(false);
    };

    const handleItemClick = (event, menuItem) => {
        menuItem.onClick && menuItem.onClick(menuItem);
    };

    const renderMenuItem = (menuItem) => {
        const renderSimpleMenuItem = () => (
            <div key={menuItem.key}>
                <MenuItem
                    onClick={(e) => handleItemClick(e, menuItem)}
                    className="AccordionDropdownMenu-menuItem"
                    key={menuItem.key}
                    component={'div'}
                >
                    <StyledCaption key={menuItem.key}>
                        {menuItem.checked !== undefined && (
                            <CheckedIcon hide={!menuItem.checked} />
                        )}
                        {menuItem.content}
                    </StyledCaption>
                </MenuItem>
                {menuItem.divider && <Divider />}
            </div>
        );
        const renderMenuItemWithSubmenu = () => (
            <div key={menuItem.key}>
                <StyledAccordion>
                    <MenuItem className="AccordionDropdownMenu-menuItem">
                        <AccordionSummary expandIcon={<ChevronDownIcon />}>
                            {menuItem.content}
                        </AccordionSummary>
                    </MenuItem>
                    {menuItem.subMenuItems?.map((subMenuItem) => (
                        <AccordionDetails
                            key={subMenuItem.key}
                            data-testid="submenuItem"
                        >
                            <MenuItem
                                onClick={(e) => handleItemClick(e, subMenuItem)}
                                className="AccordionDropdownMenu-subMenuItem"
                            >
                                {subMenuItem.checked !== undefined && (
                                    <CheckedIcon hide={!subMenuItem.checked} />
                                )}

                                {subMenuItem.content}
                            </MenuItem>
                        </AccordionDetails>
                    ))}
                </StyledAccordion>
                {menuItem.divider && <Divider />}
            </div>
        );

        const hasSubmenu =
            menuItem.subMenuItems && menuItem.subMenuItems.length > 0;

        return hasSubmenu
            ? renderMenuItemWithSubmenu()
            : renderSimpleMenuItem();
    };

    return (
        <>
            {isMenuOpen && <StyledOverlay />}
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box style={{ display: 'flex', position: 'relative' }}>
                    <ToolbarButton
                        onClick={handleTriggerButtonClick}
                        id={triggerButtonId}
                    >
                        {triggerButtonLabel}
                    </ToolbarButton>
                    <StyledMenuBox
                        data-testid="dropdownMenu"
                        style={{
                            display: isMenuOpen ? 'block' : 'none',
                        }}
                    >
                        {menuItems.map((menuItem) => renderMenuItem(menuItem))}
                    </StyledMenuBox>
                </Box>
            </ClickAwayListener>
        </>
    );
};

export default AccordionDropdownMenu;
