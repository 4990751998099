import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import LightTooltip from 'components/common/LightTooltip';

export default function SwitchWithTooltip({
    isChecked,
    onChange,
    label,
    tooltip,
}) {
    const style = {
        label: {
            width: '100%',
            height: '24px',
            margin: 0,
            paddingLeft: '12px',
            paddingTop: '22px',
            paddingBottom: '24px',
        },
        typography: {
            marginTop: '4px',
            lineHeight: '12px',
            marginRight: '4px',
        },
    };
    return (
        <>
            <LightTooltip
                title={tooltip}
                placement="left-start"
                arrow
                enterDelay={700}
                enterNextDelay={700}
            >
                <FormControlLabel
                    style={style.label}
                    label={
                        <Typography style={style.typography}>
                            {label}
                        </Typography>
                    }
                    labelPlacement="start"
                    control={
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width="100%"
                        >
                            <Switch
                                checked={isChecked}
                                onChange={onChange}
                                name="checked"
                                color="tertiary"
                            />
                        </Box>
                    }
                />
            </LightTooltip>
        </>
    );
}
