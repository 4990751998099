import { Menu, styled } from '@mui/material';

export const StyledLayoutDropdownMenu = styled(Menu)(({ theme }) => ({
    '& .menu-title': {
        padding: theme.spacing(1.5, 2),
        textTransform: 'uppercase',
        userSelect: 'none',
    },
    '& .menu-subtitle': {
        letterSpacing: '0.1rem',
    },
    '& .MuiMenu-paper': {
        top: '94px!important',
    },
    '& .MuiList-root': {
        width: '500px !important',
    },
    '& .MuiMenuItem-root': {
        display: 'flex',
        margin: theme.spacing(0),
        padding: theme.spacing(1.25, 1.8, 1.25, 0),
        '& .MuiListItemIcon-root': {
            margin: theme.spacing(0, 1.5, 0, 0),
            minWidth: '34px',
        },
        '& .item-text': {
            flex: '0 1 auto',
            textWrap: 'wrap',
            lineHeight: '1.5rem',
            maxHeight: '3rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            '& *': { lineHeight: '1.5rem' },
            '&:not(:last-child)': {
                marginRight: theme.spacing(0.5),
            },
        },
        '& *:last-child:not(.item-text)': {
            marginLeft: 'auto',
        },
        '&.Mui-selected': {
            cursor: 'default',
        },
    },
    '& .MuiDivider-root': {
        margin: theme.spacing(0.3, 0),
    },
    '& .MuiAccordionDetails-root': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
}));

export const LayoutsButtonStack = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: theme.spacing(1.5),
    width: '100%',
    padding: theme.spacing(1.5, 2),
    '& *': { flex: '1 1 0' },
    '& .MuiBadge-root': {
        '& .MuiBadge-badge': {
            color: theme.palette.primary.main,
            top: '4px',
            right: '4px',
        },
        '& .MuiButton-root': {
            textTransform: 'none !important',
            fontSize: '16px !important',
            fontWeight: '400 ',
            padding: theme.spacing(2, 1),
            backgroundColor: theme.palette.grey[100],
            borderWidth: theme.spacing(0.2),
            borderColor: theme.palette.grey[400],
        },
        '&.Fort-selected': {
            '& .MuiSvgIcon-root': { color: 'rgba(75,88,140,1)' },
            '& .MuiButton-root': { borderColor: '#4B588C' },
        },
    },
}));
