const getGroup = (state) => state.user.group;

export const getUserId = (state) => state?.user?.userId;

export function currentGridOptions(state, type) {
    const gridTypeOptions = state.user.gridOptions[type];
    if (!gridTypeOptions) {
        return undefined;
    }

    const group = getGroup(state);

    if (!group) {
        return undefined;
    }

    return gridTypeOptions[group.id];
}

export function currentGridCreationIndex(state, type) {
    return state.user.creationIndex[type] || 0;
}

export function isAdmin(state) {
    return state.user.isAdmin;
}

export function isNewThemeEnabled(state) {
    return state.user.themeSettings.newThemeEnabled;
}
