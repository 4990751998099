import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FortDownshiftInput from '_legacy/components/grid/inputs/FortDownshiftInput';
import ThirdPartyCompanyApi from '_legacy/api/thirdPartyCompanyApi';
import {
    ENTITY_PART_TYPE_PLACEHOLDER,
    ENTITY_PART_TYPE_CUSTOM,
} from '_legacy/models/common/EntityPart';
import ThirdPartyCompanyClientCheck from 'components/common/ThirdPartyCompany/ThirdPartyCompanyClientCheck';
import ThirdPartyCompanySearchResult from 'components/common/ThirdPartyCompany/ThirdPartyCompanySearchResult';
import MixpanelLogger from '_legacy/diagnostics/logging/mixpanelLogger';

class ThirdPartyCompanyInput extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.state = {
            initialChar: props.initialChar,
        };
    }

    itemToString = (company) => (company ? company.name : '');

    handleItemSelected = (selectedCompany, keyCode, shift) => {
        if (this.props.onCompanySelected) {
            const company = this.convertRetrievedCompany(selectedCompany);
            this.props.onCompanySelected(company, keyCode, shift);
        }
    };

    handleDelete = () => {
        if (this.props.onDelete) {
            this.props.onDelete();
        }
    };

    trackMixpanelEvent = (isNonClient) => {
        MixpanelLogger.trackCreateCompanyDropdownButtonClick(
            this.props.columnName,
            isNonClient
        );
    };

    convertRetrievedCompany(company) {
        return {
            value: company.id.toString(),
            partType:
                // this check is to keep basic logic of custom value in FortDownshiftInput
                // (show in the list without capturing immediately like we lusualy do for placeholders) but to DB it should go like 'Placeholder'
                company.type === ENTITY_PART_TYPE_CUSTOM
                    ? ENTITY_PART_TYPE_PLACEHOLDER
                    : company.type,
            name: company.name,
        };
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    reset() {
        if (this.inputRef.current) {
            this.inputRef.current.reset();
        }
    }

    renderCompany(getItemProps, highlightedIndex, item, index) {
        return (
            <ThirdPartyCompanySearchResult
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                item={item}
                index={index}
            />
        );
    }

    render() {
        const toClientCheck = (
            <tr>
                <td colSpan={3}>
                    <ThirdPartyCompanyClientCheck
                        openNewCompanyModal={this.props.openNewCompanyModal}
                        onClick={this.trackMixpanelEvent}
                    />
                </td>
            </tr>
        );

        return (
            <FortDownshiftInput
                {...this.props}
                initialChar={this.state.initialChar}
                ref={this.inputRef}
                itemToString={this.itemToString}
                searchFunc={
                    this.props.searchFunc ?? ThirdPartyCompanyApi.search
                }
                renderItem={this.renderCompany}
                placeholder={
                    this.props.placeholder === undefined
                        ? 'Company name'
                        : this.props.placeholder
                }
                customItemName={this.props.placeholderName}
                minCharectersForSearch={2}
                toClientCheck={
                    this.props.openNewCompanyModal ? toClientCheck : null
                }
                onItemSelected={this.handleItemSelected}
                onDelete={this.handleDelete}
                onInputCleared={this.props.onInputCleared}
                onTab={this.props.onTab}
                onTabBack={this.props.onTabBack}
                blockedCharacters={this.props.blockedCharacters}
                downshiftContentClassname={
                    this.props.downshiftContentClassname ??
                    'third-party-company-downshift-content'
                }
            />
        );
    }
}

ThirdPartyCompanyInput.propTypes = {
    onCompanySelected: PropTypes.func.isRequired,
    shouldSelectItemOnTab: PropTypes.bool.isRequired,
    inputClass: PropTypes.string,
    onTab: PropTypes.func.isRequired,
    onTabBack: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    initialChar: PropTypes.string,
    onInputCleared: PropTypes.func.isRequired,
    openNewCompanyModal: PropTypes.func,
    downshiftContentClassname: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    searchFunc: PropTypes.func,
    columnName: PropTypes.string,
};

export default ThirdPartyCompanyInput;
