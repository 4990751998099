import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateNodeTree from '../../../tools/DateNodeTree';
import FilterTreeView from './FilterTreeView';
import { blankKeyText } from '../../../constants/gridText';
import { FilterGenerator } from './FilterGenerator';
import { TextField } from '@mui/material';
import './DateFilter.scss';

class DateFilter extends Component {
    constructor(props) {
        super(props);
        this.handleBlanksChange = this.handleBlanksChange.bind(this);
        this.handleAllDatesChange = this.handleAllDatesChange.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.handleNodeClicked = this.handleNodeClicked.bind(this);
        this.handleNodeToggled = this.handleNodeToggled.bind(this);
        this.collapseTree = this.collapseTree.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);

        this.tree = this.createTree(props.dates);

        this.state = {
            showBlanks: true,
            visibleNodes: props.visibleNodes ? props.visibleNodes : {},
            filterState: this.tree.filterState,
            expanded: [],
            startDate: '',
            endDate: '',
        };
    }

    componentDidMount() {
        this.refreshFilterTree();
        this.collapseTree();
    }

    createTree(dates) {
        //get a distinct list of values of this field from the grid and create the tree

        const stringArray = dates ? dates : [];
        this.props.api.forEachNode((node) => {
            if (node.data) {
                stringArray.push(
                    this.valueGetter(node.data[this.props.column.colId])
                );
            }
        });

        return new DateNodeTree(stringArray);
    }

    afterGuiAttached() {
        this.refreshFilterTree();
    }

    handleNodeToggled(nodes) {
        this.setState({ expanded: nodes });
    }

    collapseTree() {
        this.setState({ expanded: [] });
    }

    refreshFilterTree() {
        const currentFilterState = this.tree.filterState;
        this.tree = this.createTree();
        this.tree.setFilterState(currentFilterState);
        const visibleNodes = {};

        const startDate = this.state.startDate
            ? new Date(this.state.startDate)
            : null;
        const endDate = this.state.endDate
            ? new Date(this.state.endDate)
            : null;

        const isRowVisible = this.props.doesRowPassOtherFilter;
        this.props.api.forEachNode((node) => {
            if (node.data && isRowVisible(node)) {
                const primaryKey = this.valueGetter(
                    node.data[this.props.column.colId]
                );
                let key = primaryKey;

                const date = key ? new Date(key) : null;

                if (startDate && endDate) {
                    key = date >= startDate && date <= endDate ? key : null;
                } else if (startDate) {
                    key = date >= startDate ? key : null;
                } else if (endDate) {
                    key = date <= endDate ? key : null;
                }

                if (date && !key) {
                    this.tree.setCheckbox(
                        primaryKey,
                        DateNodeTree.States.UNCHECKED
                    );
                }

                const hierarchy = this.tree.getNodeAncestors(key);
                if (hierarchy) {
                    if (startDate || endDate) {
                        this.tree.setCheckbox(key, DateNodeTree.States.CHECKED);
                    }
                    hierarchy.forEach((i) => (visibleNodes[i] = true));
                }
            }
        });

        this.setState({ filterState: this.tree.filterState, visibleNodes });
    }

    handleBlanksChange(_, checked) {
        if (this.state.startDate || this.state.endDate) {
            this.tree.setCheckbox('_', DateNodeTree.States.CHECKED);
        }

        this.setState(
            {
                filterState: this.tree.filterState,
                showBlanks: checked,
                startDate: '',
                endDate: '',
            },
            () => {
                this.refreshFilterTree();
                this.props.filterChangedCallback();
            }
        );
    }

    handleAllDatesChange(_, checked) {
        const state = checked
            ? DateNodeTree.States.CHECKED
            : DateNodeTree.States.UNCHECKED;
        this.tree.setCheckbox('_', state);

        this.setState(
            { filterState: this.tree.filterState, startDate: '', endDate: '' },
            () => {
                this.refreshFilterTree();
                this.props.filterChangedCallback();
            }
        );
    }

    handleNodeClicked(treeNode, checked) {
        let { showBlanks } = this.state;

        this.tree.setCheckbox(
            treeNode.key,
            checked
                ? DateNodeTree.States.CHECKED
                : DateNodeTree.States.UNCHECKED
        );

        this.setState({ showBlanks, filterState: this.tree.filterState }, () =>
            this.props.filterChangedCallback()
        );
    }

    clearFilter() {
        this.tree.clearFilter();
        const defaultDate = '0001-01-01';

        // We should do that trick for clearing dates because in case of partially filled date
        // inputs will not be cleared (looks like TextField input have internal state, when our state is empty soom, so input could not see changes).
        // But if we populate input with full date then we can clear that input by setting empty value.
        this.setState({ startDate: defaultDate, endDate: defaultDate }, () => {
            this.setState(
                {
                    showBlanks: true,
                    filterState: this.tree.filterState,
                    startDate: '',
                    endDate: '',
                },
                () => {
                    this.refreshFilterTree();
                    this.props.filterChangedCallback();
                }
            );
        });
    }

    valueGetter(rowValue) {
        let result;
        if (this.props.fieldName && rowValue) {
            result = rowValue[this.props.fieldName];
        } else {
            result = rowValue;
        }
        if (result) {
            result = result.slice(0, 10);
        }
        if (this.props.appendTime && rowValue && result) {
            result = result.concat('T00:00:00Z');
        }
        return result;
    }

    isShowingAllDates = () => {
        return this.tree.filterState.dateFilterSet.includes(
            DateNodeTree.RootNodeKey
        );
    };

    isFilterActive() {
        return !this.state.showBlanks || !this.isShowingAllDates();
    }

    doesFilterPass(params) {
        const { showBlanks } = this.state;
        const dateAsString = this.valueGetter(
            this.props.valueGetter(params.node)
        );

        if (!dateAsString && showBlanks) {
            return true;
        }

        if (dateAsString && this.isShowingAllDates()) {
            return true;
        }

        if (dateAsString) {
            return this.tree.isChecked(dateAsString);
        }

        return false;
    }

    getModel() {
        const { filterState, showBlanks, startDate, endDate } = this.state;

        return this.isShowingAllDates() && showBlanks
            ? null
            : { filterState, showBlanks, startDate, endDate };
    }

    setModel(model) {
        if (model) {
            this.tree.setFilterState(model.filterState);
            this.setState(model, () => this.props.filterChangedCallback());
        } else {
            this.clearFilter();
        }
    }

    setStartDate(event) {
        const shouldBeChecked =
            event.target.value === '' && this.state.endDate === '';

        this.setState(
            {
                startDate: event.target.value,
                showBlanks: shouldBeChecked,
                showInDC: shouldBeChecked,
            },
            () => {
                this.refreshFilterTree();
                this.props.filterChangedCallback();
            }
        );
    }

    setEndDate(event) {
        const shouldBeChecked =
            event.target.value === '' && this.state.startDate === '';

        this.setState(
            {
                endDate: event.target.value,
                showBlanks: shouldBeChecked,
                showInDC: shouldBeChecked,
            },
            () => {
                this.refreshFilterTree();
                this.props.filterChangedCallback();
            }
        );
    }

    render() {
        const visibleNodes = this.state.visibleNodes;

        const elements = [
            [
                {
                    type: 'checkbox',
                    checked: this.state.showBlanks,
                    onChange: this.handleBlanksChange,
                    label: blankKeyText,
                },
                {
                    type: 'checkbox',
                    checked: this.isShowingAllDates(),
                    onChange: this.handleAllDatesChange,
                    label: 'ALL DATES',
                },
            ],
            <div>
                <form noValidate>
                    <TextField
                        id="date"
                        label="From"
                        type="date"
                        value={this.state.startDate}
                        onChange={this.setStartDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </form>
                <form noValidate style={{ paddingTop: '5px' }}>
                    <TextField
                        id="date"
                        label="To"
                        type="date"
                        value={this.state.endDate}
                        onChange={this.setEndDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </form>
            </div>,
            <FilterTreeView
                tree={this.tree}
                visibleNodes={visibleNodes}
                expanded={this.state.expanded}
                onNodeClicked={this.handleNodeClicked}
                onNodeToggle={this.handleNodeToggled}
            />,
        ];

        return <FilterGenerator elements={elements} clear={this.clearFilter} />;
    }
}

DateFilter.propTypes = {
    filterChangedCallback: PropTypes.func.isRequired,
};

export default DateFilter;
