import React from 'react';
import Header from '../Header';
import { ListItem, Collapse } from '@mui/material';
import DirectionLogicItems from './DirectionLogicItems';
import QuantityFormatItems from './QuantityFormatItems';
import DefaultTypeItems from './DefaultTypeItems';

const CommonSettingsToolPanel = (props) => {
    const [isDirectionLogicOpen, setIsDirectionLogicOpen] =
        React.useState(true);
    const [isQuantityFormatOpen, setIsQuantityFormatOpen] =
        React.useState(true);
    const [isDefaultTypeOpen, setIsDefaultTypeOpen] = React.useState(true);

    const handleDirectionLogicClick = () => {
        setIsDirectionLogicOpen(!isDirectionLogicOpen);
    };

    const handleQuantityFormatClick = () => {
        setIsQuantityFormatOpen(!isQuantityFormatOpen);
    };

    const handleDefaultTypeClick = () => {
        setIsDefaultTypeOpen(!isDefaultTypeOpen);
    };

    const checkDirectionDisplayConditions = () => {
        const availableColumns =
            props.columnApi && props.columnApi.getAllColumns();
        if (availableColumns) {
            const directionColumn = availableColumns.find(
                (column) => column.colId === 'direction'
            );

            return !!directionColumn;
        }
    };

    return (
        <>
            <div className="ag-unselectable ag-column-drop ag-column-drop-vertical ag-last-column-drop">
                <ListItem button onClick={handleQuantityFormatClick}>
                    <Header
                        name="Quantity Format"
                        icon="ag-icon-columns"
                        isOpen={isQuantityFormatOpen}
                    />
                </ListItem>
                <Collapse
                    in={isQuantityFormatOpen}
                    timeout="auto"
                    unmountOnExit
                >
                    <QuantityFormatItems {...props} />
                </Collapse>
            </div>
            {checkDirectionDisplayConditions() && (
                <div className="ag-unselectable ag-column-drop ag-column-drop-vertical ag-last-column-drop">
                    <ListItem button onClick={handleDirectionLogicClick}>
                        <Header
                            name="Direction Logic"
                            icon="ag-icon-columns"
                            isOpen={isDirectionLogicOpen}
                        />
                    </ListItem>
                    <Collapse
                        in={isDirectionLogicOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <DirectionLogicItems {...props} />
                    </Collapse>
                </div>
            )}
            <div className="ag-unselectable ag-last-column-drop">
                <ListItem button onClick={handleDefaultTypeClick}>
                    <Header
                        name="Default Type"
                        icon="ag-icon-columns"
                        isOpen={isDefaultTypeOpen}
                    />
                </ListItem>
                <Collapse in={isDefaultTypeOpen} timeout="auto" unmountOnExit>
                    <DefaultTypeItems {...props} />
                </Collapse>
            </div>
        </>
    );
};

export default CommonSettingsToolPanel;
