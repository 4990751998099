import { Checkbox, FormControlLabel } from '@mui/material';

const FilterLabels = (props) => {
    return props.filters.map((filter) => (
        <FormControlLabel
            sx={{ fontFamily: 'Cera GR Medium', fontSize: '13px' }}
            key={filter.key}
            control={
                <Checkbox
                    sx={{ padding: '5px' }}
                    color="primary"
                    checked={filter.checked}
                    onChange={props.handleFilterChange}
                    name={filter.key}
                />
            }
            label={filter.label}
        />
    ));
};

export default FilterLabels;
