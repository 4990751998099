import '@fontsource/noto-sans/100.css';
import '@fontsource/noto-sans/200.css';
import '@fontsource/noto-sans/300.css';
import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/600.css';
import '@fontsource/noto-sans/700.css';
import '@fontsource/noto-sans/800.css';
import '@fontsource/noto-sans/900.css';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import { createTheme } from '@mui/material/styles';
import * as muiColors from '@mui/material/colors';

const appColors = {
    ...muiColors,
    grey: { ...muiColors.grey, 300: '#E2E2E2', 800: '#404040' },
    indigo: { ...muiColors.indigo, 900: '#203171' },
    teal: { ...muiColors.teal, A400: '#6DDECD' },
};

export const appFonts = {
    CeraGR: "'Cera GR', sans-serif",
    CeraGRMedium: "'Cera GR Medium', sans-serif",
    CeraGRBold: "'Cera GR Bold', sans-serif",
    NotoSans: "'Noto Sans', sans-serif",
    Roboto: "'Roboto', sans-serif",
};

let appTheme = createTheme({
    typography: {
        fontFamily: appFonts.CeraGRMedium,
        h1: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
        },
        h2: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
        },
        h3: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
        },
        h4: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
            fontSize: '16px',
        },
        h5: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
            fontSize: '14px',
        },
        h6: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
            fontSize: '12px',
        },
    },
    components: {
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    '&, *': {
                        userSelect: 'none',
                        fontFamily: appFonts.NotoSans,
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: appColors.grey[800],
                    '&:hover': {
                        backgroundColor: appColors.grey[300],
                    },
                    '&.Mui-selected': {
                        color: appColors.common.black,
                        backgroundColor: appColors.grey[300],
                        '&:hover': {
                            backgroundColor: appColors.grey[300],
                        },
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontFamily: appFonts.NotoSans,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
            styleOverrides: {
                root: {
                    '&, & *': {
                        fontFamily: appFonts.Roboto,
                    },
                },
            },
        },
    },
});

appTheme = createTheme(appTheme, {
    palette: {
        primary: {
            main: appColors.indigo[900],
        },
        secondary: appTheme.palette.augmentColor({
            color: {
                main: appColors.teal.A400,
            },
            name: 'teal',
        }),
        tertiary: appTheme.palette.augmentColor({
            color: {
                main: '#E1315A',
            },
            name: 'pink',
        }),
        info: {
            main: appColors.grey[700],
        },
        grey: appColors.grey,
    },
});

export default appTheme;
