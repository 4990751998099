import {
    ColumnApi,
    ColumnEvent,
    GridApi,
    RowNode,
} from '@ag-grid-enterprise/all-modules';
import DateComparator from '_legacy/components/grid/comparators/DateComparator';
import LoadZoneComparator from '_legacy/components/grid/comparators/LoadZoneComparator';
import {
    DATASET_AFRAMAX_LONDON,
    DATASET_CLEAN_LONDON,
    DATASET_FUEL_OIL_LONDON,
    DATASET_SUEZMAX_HOUSTON,
    DATASET_SUEZMAX_LONDON,
} from '_legacy/models/Datasets';
import CategoryComparator from '_legacy/modules/columns/vessel/comparators/CategoryComparator';
import _ from 'lodash';
import { BaseGridConfig } from 'store/feature/layoutsSlice/models';
import { firstBy } from 'thenby';
import { GridComponent } from './models';
import { getUserId } from '../../../_legacy/reducers/userSelectors';
import store from 'store';

export module GridUtils {
    export function initializeGridColumns(
        columnApi: ColumnApi,
        userColumnOptions: BaseGridConfig['columnOptions']
    ): void {
        if (columnApi) {
            const columns = columnApi.getAllColumns() ?? [];
            //show columns which cannot be hidden, in case they were previously and had been stored in column state
            const columnsCannotBeHidden = columns.filter(
                (c) =>
                    c.getColDef().suppressColumnsToolPanel &&
                    c.getColDef().suppressMovable
            );

            columnApi.setColumnsVisible(
                columnsCannotBeHidden.map((c) => c.getColId()),
                true
            );

            const existingColIds = (userColumnOptions || []).map(
                (c) => c.colId
            );

            //find columns that have a width set but aren't contained in initial columns
            //as we don't want to override widths the user has already defined
            const columnsWithWidths = columns.filter(
                (c) =>
                    c.getColDef().width &&
                    existingColIds.indexOf(c.getColId()) === -1
            );

            //set widths to those originally specified
            for (let index = 0; index < columnsWithWidths.length; index++) {
                const col = columnsWithWidths[index];
                //override anything that has been restored
                columnApi.setColumnWidth(
                    col.getColId(),
                    col.getColDef().width ?? 100
                );
            }
        }
    }

    export function updateTimestamp(api: GridApi): void {
        _.set(api, '__timestamp', new Date().getTime());
    }

    export function isLaterThanTimestamp(
        api: GridApi,
        input: string | Date | undefined
    ): boolean {
        const timestamp = _.get(api, '__timestamp', new Date().getTime());
        if (typeof input === 'string') {
            return new Date(input).getTime() > timestamp;
        } else if (input instanceof Date) {
            return input.getTime() > timestamp;
        } else {
            return false;
        }
    }

    export function wrapFilterInstances(api: GridApi): void {
        const filterModel = api.getFilterModel();
        const userId = getUserId(store.getState());
        _(filterModel)
            .keys()
            .forEach((colId) => {
                const fInstance: Record<string, any> | undefined =
                    api.getFilterInstance(colId)!;
                if (fInstance && !fInstance.__doesFilterPass) {
                    fInstance.__doesFilterPass = fInstance.doesFilterPass;
                    fInstance.doesFilterPass = (params) => {
                        if (
                            isLaterThanTimestamp(
                                params.node.gridApi,
                                params.data.createdDateTime
                            ) &&
                            params.data.createdBy.userId === userId
                        ) {
                            return true;
                        }
                        return fInstance.__doesFilterPass(params);
                    };
                }
            });
    }

    export function reloadRowGroupingIndentation(
        newThemeEnabled: boolean,
        columnApi: ColumnApi
    ): void {
        const currentColumnModel = columnApi.getColumnState();
        const isSelectedColumn = currentColumnModel.find(
            (column) => column.colId === 'isSelected'
        );

        const rowGroupCount = currentColumnModel.filter(
            (column) => column.rowGroup
        ).length;
        if (isSelectedColumn) {
            if (newThemeEnabled) {
                const width = rowGroupCount > 1 ? 77 : 35;
                isSelectedColumn.width = width;
                (isSelectedColumn as any).minWidth = width;

                columnApi.setColumnState(currentColumnModel);
            }
        }
    }

    export function columnsPostSort(
        rowNodes: RowNode[],
        sortingIsActive: boolean,
        datasetId: number
    ): void {
        if (rowNodes.length === 0) return;

        //if sorting has been applied by the user,
        //other mechanisms ensure the sorting is consistent via created date time comparators
        if (!sortingIsActive) {
            //if no sorting has been applied by the user, we should reorder the rows to be ordered by created date time, load zone and
            //reported date descending leaving the most recently inserted records data at the top of the grid, as expected by the users.
            rowNodes.sort(
                firstBy<RowNode>((n1, n2) => {
                    if (n1.data && n2.data) {
                        return DateComparator(
                            n1.data.reportedDate,
                            n2.data.reportedDate
                        );
                    }
                    return 0;
                }, -1)
                    .thenBy<RowNode>((n1, n2) => {
                        if (
                            datasetId === DATASET_SUEZMAX_LONDON ||
                            datasetId === DATASET_SUEZMAX_HOUSTON ||
                            datasetId === DATASET_AFRAMAX_LONDON ||
                            datasetId === DATASET_FUEL_OIL_LONDON
                        ) {
                            if (n1.data && n2.data) {
                                return LoadZoneComparator(
                                    n1.data.loadZone,
                                    n2.data.loadZone,
                                    n1,
                                    n2
                                );
                            }
                        } else if (datasetId === DATASET_CLEAN_LONDON) {
                            if (n1.data && n2.data) {
                                return CategoryComparator(
                                    n1.data.vesselCategory,
                                    n2.data.vesselCategory,
                                    n1,
                                    n2
                                );
                            }
                        }

                        return 0;
                    })
                    .thenBy<RowNode>((n1, n2) => {
                        if (n1.data && n2.data) {
                            return DateComparator(
                                n1.data.createdDateTime,
                                n2.data.createdDateTime
                            );
                        }
                        return 0;
                    }, -1)
            );
        }

        const sessionNodes: RowNode[] = [];
        let i = 0;
        while (i < rowNodes.length) {
            const { data, gridApi } = rowNodes[i] as any;
            if (data && isLaterThanTimestamp(gridApi, data.createdDateTime)) {
                sessionNodes.push(...rowNodes.splice(i, 1));
            } else {
                i++;
            }
        }
        if (sessionNodes.length > 0) {
            sessionNodes.sort((n1, n2) => {
                if (n1.data && n2.data) {
                    return -DateComparator(
                        n1.data.createdDateTime,
                        n2.data.createdDateTime
                    );
                }
                return 0;
            });
            rowNodes.unshift(...sessionNodes);
        }
    }

    export function updateRowGroupsIfNeeded(
        { columnApi }: Pick<GridComponent, 'api' | 'columnApi'>,
        rowGroupIds: string[]
    ): void {
        const currentRowGroups = columnApi
            .getRowGroupColumns()
            .map((c) => c.getColId());
        if (!_.isEqual(currentRowGroups, rowGroupIds)) {
            columnApi.setRowGroupColumns(rowGroupIds);
        }
    }

    export function isColumnEventEligible(e: ColumnEvent): boolean {
        const source = e.source?.toLowerCase() ?? '';
        return (
            e.type === 'sortChanged' ||
            source.startsWith('ui') ||
            source.endsWith('ui')
        );
    }

    function collapseRowGroup(api: any, groupPath): void {
        const rootNode = api.rowModel.rootNode;
        const groupLevels = groupPath.split('_');
        groupLevels.shift(); // 'root' at the begginig is not needed

        let node = rootNode;
        groupLevels.forEach(
            (groupLevel) =>
                (node =
                    node && groupLevel in node.childrenMapped
                        ? node.childrenMapped[`${groupLevel}`]
                        : null)
        );

        if (node && node.expanded) {
            node.setExpanded(false);
        }
    }

    export function updateCollapsedRowGroups(
        { api }: Pick<GridComponent, 'api'>,
        collapsedRowGroups: string[]
    ): void {
        api.expandAll();
        collapsedRowGroups.forEach((groupPath) =>
            collapseRowGroup(api, groupPath)
        );
    }
}
