import _ from 'lodash';
import { useEffect, useMemo, useReducer } from 'react';
import {
    LayoutMenuState,
    updateLayoutMenuSettings,
    userSelectors,
} from 'store/feature/userSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const DEBOUNCE_TIME = 2000;

function useLayoutMenuState(): {
    state: LayoutMenuState;
    toggle: { [K in keyof LayoutMenuState]: () => void };
} {
    const dispatch = useAppDispatch();
    const layoutMenuState = useAppSelector(userSelectors.layoutMenu);

    const [userSectionExpanded, toggleUserSectionExpanded] = useReducer(
        (state) => !state,
        layoutMenuState.userSectionExpanded
    );
    const [adminTemplateSectionExpanded, toggleAdminTemplateSectionExpanded] =
        useReducer(
            (state) => !state,
            layoutMenuState.adminTemplateSectionExpanded
        );
    const [templateSectionExpanded, toggleTemplateSectionExpanded] = useReducer(
        (state) => !state,
        layoutMenuState.templateSectionExpanded
    );

    const handleUpdateLayoutMenuSettings = useMemo(
        () =>
            _.debounce((s: LayoutMenuState) => {
                dispatch(updateLayoutMenuSettings(s));
            }, DEBOUNCE_TIME),
        [dispatch]
    );
    useEffect(() => {
        const actualState = {
            userSectionExpanded,
            adminTemplateSectionExpanded,
            templateSectionExpanded,
        };
        handleUpdateLayoutMenuSettings(actualState);
    }, [
        userSectionExpanded,
        adminTemplateSectionExpanded,
        templateSectionExpanded,
        handleUpdateLayoutMenuSettings,
    ]);

    return {
        state: {
            userSectionExpanded,
            adminTemplateSectionExpanded,
            templateSectionExpanded,
        },
        toggle: {
            userSectionExpanded: toggleUserSectionExpanded,
            adminTemplateSectionExpanded: toggleAdminTemplateSectionExpanded,
            templateSectionExpanded: toggleTemplateSectionExpanded,
        },
    };
}

export default useLayoutMenuState;
